/* eslint-disable no-underscore-dangle */
import {
  HttpEventType,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

type UploadStatus = 'success' | 'fail' | 'ongoing' | 'nofile';
type SentHeaderEvent = HttpSentEvent | HttpHeaderResponse;
type ProgressUserEvent = HttpProgressEvent | HttpUserEvent<NonNullable<unknown>>;
type UploadEvents = SentHeaderEvent | HttpResponse<NonNullable<unknown>> | ProgressUserEvent;
type UploadCall = ((file: File) => Observable<UploadEvents>) | undefined;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {
  @Input()
  accept = '.xls,.xlsx';

  @Input()
  backendUploadCall: UploadCall;

  private readonly maxFileSizeInMB = 15;

  private _filename: string | undefined;

  @Input()
  set filename(fr: string | undefined) {
    this._filename = fr;
    if (this.filename) {
      this.uploadStatus = 'success';
      this.itIsReplaceUpload = true;
    }
  }

  get filename(): string | undefined {
    return this._filename;
  }

  itIsReplaceUpload = false;

  uploadStatus: UploadStatus = 'nofile';

  uploadProgress: number | undefined;

  get uploadStatusIcon(): string {
    switch (this.uploadStatus) {
      case 'fail': {
        return 'cop-error';
      }
      case 'success': {
        return 'cop-file';
      }
      default: {
        return 'cop-upload';
      }
    }
  }

  onFileChange(event: Event): void {
    if (event.target) {
      const fileList = (event.target as HTMLInputElement).files;
      if (fileList) {
        const file = fileList.item(0);
        if (file) {
          this.handleFileUpload(file);
        }
      }
    }
  }

  private handleFileUpload(file: File): void {
    try {
      this.validateFile(file);

      this._filename = file.name;
      this.uploadStatus = 'ongoing';

      this.uploadDocumentToBackend(file).subscribe({
        next: (httpEvent) => {
          switch (httpEvent.type) {
            case HttpEventType.Sent: {
              this.uploadProgress = 0;
              break;
            }
            case HttpEventType.UploadProgress: {
              this.uploadProgress = httpEvent.total ? Math.round((httpEvent.loaded / httpEvent.total) * 100) : 50;
              break;
            }
            case HttpEventType.Response: {
              this.uploadStatus = httpEvent.body ? 'success' : 'fail';
              break;
            }
            default: {
              break;
            }
          }
        },
        error: () => {
          this.uploadStatus = 'fail';
        },
      });
    } catch {
      this.uploadStatus = 'fail';
    }
  }

  private validateFile(file: File): void {
    const allowedExtensionsRegex = this.convertAcceptedExtensionsStringToRegEx();
    if (!allowedExtensionsRegex.test(file.name)) {
      throw new Error('Extension not supported');
    }

    const megaByte = 1024 * 1024;
    if (file.size >= megaByte * this.maxFileSizeInMB) {
      throw new Error('File must be smaller than 15MB');
    }
  }

  private convertAcceptedExtensionsStringToRegEx(): RegExp {
    const allowedExtensions = `(${this.accept.replaceAll(',', '|\\')})$`;
    // eslint-disable-next-line security/detect-non-literal-regexp
    return new RegExp(allowedExtensions);
  }

  private uploadDocumentToBackend(file: File): Observable<UploadEvents> {
    if (!this.backendUploadCall) {
      throw new Error('uploadDocumentToBackendFunction is missing on FileUploadComponent, but it is required');
    }

    return this.backendUploadCall(file);
  }
}
