import { Directive, HostListener, Input, ViewContainerRef } from '@angular/core';
import { OffCanvasComponent } from '@/shared/components/fragments/off-canvas/off-canvas.component';
import {
  OffCanvasInjectionService,
  OffCanvasObject,
} from '../services/component-injection/off-canvas-injection.service';

@Directive({
  selector: '[appOffCanvasTrigger]',
})
export class OffCanvasTriggerDirective {
  @Input('appOffCanvasTrigger')
  offCanvasObject!: OffCanvasObject;

  constructor(
    private readonly offCanvasInjectionService: OffCanvasInjectionService,
    public viewContainerReference: ViewContainerRef,
  ) {}

  @HostListener('click')
  showOffCanvasExplanation(): void {
    this.offCanvasInjectionService.show(this.offCanvasObject, OffCanvasComponent, this.viewContainerReference);
  }
}
