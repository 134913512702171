import { Injectable } from '@angular/core';
import { amlDeskRoutes } from '@core/route-map';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { KYCRoutes } from '@kyc/route-map-kyc';
import { WorkItemType } from '@kyc/types/kyc.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private readonly kycItemTypeCacheService: KycItemTypeCacheService) {}

  /**
   * Returns an observable of the link based on work item types.
   * @return {Observable<string>}
   */
  public getLink(): Observable<string> {
    return this.kycItemTypeCacheService.workItemTypes$.pipe(map((workItemTypes) => this.determineLink(workItemTypes)));
  }

  /**
   * Determines the link based on work item types.
   * @param {WorkItemType[] | null} workItemTypes
   * @return {string}
   */
  private determineLink(workItemTypes: WorkItemType[] | null): string {
    const isDDWorkItemType = workItemTypes
      ? workItemTypes.some((item) => item.referenceName?.includes('ddt-') || item.referenceName?.includes('kyc-'))
      : false;

    return isDDWorkItemType ? `/${amlDeskRoutes.kyc}/${KYCRoutes.introduction}` : `/${amlDeskRoutes.dashboard}`;
  }
}
