/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
})
export class FormFieldComponent implements OnInit, ControlValueAccessor {
  @Input()
  fieldErrorMessage = 'There is an error in the field';

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  type = 'text';

  disabled = false;

  value?: string;

  required = false;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.required = this.formControlHasValidatorRequired();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string): void => {};

  onTouched = (): void => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(functionRegisterOnChange: (value: string) => void): void {
    this.onChange = functionRegisterOnChange;
  }

  registerOnTouched(functionRegisterOnTouched: () => void): void {
    this.onTouched = functionRegisterOnTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  internalOnChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.onChange(inputElement.value);
  }

  showError(): boolean {
    return !!this.ngControl.control?.invalid && this.ngControl.control.touched;
  }

  private formControlHasValidatorRequired(): boolean {
    if (!this.ngControl || !this.ngControl.control) {
      return false;
    }

    // get AbstractControlDirective.control(): AbstractControl<any, TValue
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const controlValue = this.ngControl.control.value;
    const errors = this.ngControl.control.errors;

    // Temporarily set a value to the control to see if it affects the 'required' validation status
    this.ngControl.control.setValue('');
    const isRequired = this.ngControl.control.hasError('required');

    // Revert the control's value and error state
    this.ngControl.control.setValue(controlValue);
    this.ngControl.control.setErrors(errors);

    return isRequired;
  }
}
