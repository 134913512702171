import { Component, Input } from '@angular/core';

export type ButtonForm = '' | 'outline' | 'circle' | 'link';
export type ButtonVariant = '' | 'primary' | 'secondary' | 'inverted' | 'inverted-brand' | 'error';
export type ButtonSize = '' | 'xs' | 'sm' | 'lg';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class')
  classList = '';

  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';

  @Input() form: ButtonForm = '';

  @Input() variant: ButtonVariant = '';

  @Input() size: ButtonSize = '';

  @Input() block = false;

  @Input() disabled = false;

  @Input() icon?: string;

  @Input() iconOnly = false;

  @Input() iconRight = false;

  @Input() inlineIcon = false;
}
