import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortOrder } from '../../../pipes/sort-data.pipe';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
})
export class SortButtonComponent {
  @Input() active = false;

  @Output()
  sort = new EventEmitter<SortOrder>();

  sortDirection: SortOrder = 'asc';

  toggleSortDirection($event: { preventDefault: () => void; stopPropagation: () => void }): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sort.emit(this.sortDirection);
  }
}
