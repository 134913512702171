import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileDownloadService } from '../../shared/services/file/file-download.service';

@Component({
  selector: 'app-text-video-section',
  templateUrl: './text-video-section.component.html',
  providers: [FileDownloadService],
})
export class TextVideoSectionComponent {
  @Input() indexSection = '';

  @Input() blockHeader = '';

  @Input() blockText = '';

  @Input() set videoSrc(url: string) {
    this._videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @Input() title = '';

  // eslint-disable-next-line unicorn/no-null
  @Input() downloadButtonLink: string | null = null;

  // eslint-disable-next-line unicorn/no-null
  @Input() downloadButtonLabel: string | null = null;

  @Input() registerButtonLink = '';

  @Input() registerButtonLabel = '';

  _videoSrc?: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly fileService: FileDownloadService,
  ) {}

  /**
   * Downloads the file from the provided link.
   * @returns void
   */
  download(): void {
    if (!this.downloadButtonLink) {
      return;
    }
    this.fileService.download(this.downloadButtonLink);
  }
}
