import { createFeatureSelector, createSelector } from '@ngrx/store';
import { navFeatureKey, NavState } from '../reducers/nav.reducers';

/**
 * Works as a bridge to access a subproperty from the store
 */
export const selectNavFeature = createFeatureSelector<NavState>(navFeatureKey);

/**
 * Uses the Feature Selector to get the opened data from
 */
export const selectIsOpened = createSelector(selectNavFeature, (state: NavState) => state?.opened);
