import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScreenSizeActions } from '@app/store/actions';
import { Industry, User } from '@core/models/user.model';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { selectIsUserLoggedIn, selectUserProfile } from '@/auth/store/selectors/auth.selectors';
import { DocumentsService } from '@/shared/services/documents.service';
import { NavActions } from '@/shared/store/actions/nav.actions';

const TAILWIND_BREAKPOINTS = {
  sm: '(max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 1023px)',
  lg: '(min-width: 1024px) and (max-width: 1439px)',
  xl: '(min-width: 1440px)',
} as const;

interface BreakPoint {
  screen: BreakPointScreen;
  mediaQuery: string;
  action: BreakPointAction;
}

const breakPoints: BreakPoint[] = [
  {
    screen: 'sm',
    mediaQuery: TAILWIND_BREAKPOINTS.sm,
    action: NavActions.toggleClose(),
  },
  {
    screen: 'md',
    mediaQuery: TAILWIND_BREAKPOINTS.md,
    action: NavActions.toggleClose(),
  },
  {
    screen: 'lg',
    mediaQuery: TAILWIND_BREAKPOINTS.lg,
    action: NavActions.toggleOpen(),
  },
  {
    screen: 'xl',
    mediaQuery: TAILWIND_BREAKPOINTS.xl,
    action: NavActions.toggleOpen(),
  },
];

const { realEstate, tradeInGoods, shared } = environment.config.dms.folders;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  userIndustry!: Industry;

  kerberosAccountId = '';

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly store: Store,
    private readonly cookieConsentService: CookieConsentService,
    private readonly documentsService: DocumentsService,
  ) {
    this.setupBreakpoints();
  }

  ngOnInit(): void {
    this.quickHideAddressBar();
    this.initializeServicesIfUserIsLoggedIn();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeServicesIfUserIsLoggedIn(): void {
    // Checks if the user is logged in to initialize the cookie consent service and fetch the documents from the user
    this.subscriptions.add(
      this.store
        .select(selectIsUserLoggedIn)
        .pipe(
          filter((isLogged) => isLogged),
          tap(() => this.cookieConsentService.initialize()),
        )
        .subscribe(() => {
          this.getCustomerIndustry();
          this.getCustomerDocuments();
          this.getSharedDocuments();
        }),
    );
  }

  private getCustomerIndustry(): void {
    this.subscriptions.add(
      // eslint-disable-next-line @ngrx/no-store-subscription
      this.store.select(selectUserProfile).subscribe((user: User | undefined) => {
        this.userIndustry = user?.industry as Industry;
        this.kerberosAccountId = user?.kerberosAccountId as string;
      }),
    );
  }

  private setupBreakpoints(): void {
    for (const breakPoint of breakPoints) {
      this.subscriptions.add(
        this.breakpointObserver
          .observe([breakPoint.mediaQuery])
          .pipe(filter((result) => result.matches))
          .subscribe(() => {
            this.store.dispatch(
              ScreenSizeActions.setScreenSizeAndPerformAction({
                screen: breakPoint.screen,
                action: breakPoint.action,
              }),
            );
          }),
      );
    }
  }

  /**
   * Maps the industry name to the corresponding folder path in the DMS configuration.
   *
   * @param {string} industry - The industry name.
   * @returns {string} - The mapped folder path for the industry in the DMS configuration.
   */
  private mapIndustryFolder(industry: Industry): string {
    const industryFolders: Record<Industry, string> = {
      [Industry.RealEstate]: realEstate,
      [Industry.TradeInGoods]: tradeInGoods,
    };

    return industryFolders[industry];
  }

  // TODO: find the document folder for the contract, now is sequential, clean this up as soon as possible
  // private mapContractFolder(contract: boolean): string {
  //   return contract ? subscription : noSubscription;
  // }

  /**
   * Get the documents shared to all documents and the documents shared to the user's industry
   * Automatically adds the results to the store cache
   */
  private getSharedDocuments(): void {
    this.fetchDocument(shared);
    this.fetchDocument(this.mapIndustryFolder(this.userIndustry));
  }

  private fetchDocument(folder: string): void {
    this.documentsService.getWithQuery({
      'folders[]': folder,
    });
  }

  /**
   * Makes a request to get the documents that belongs to the customer
   * Automatically adds the results to the store cache
   */
  private getCustomerDocuments(): void {
    this.subscriptions.add(this.documentsService.getCustomerDocuments().subscribe());
  }

  private quickHideAddressBar(): void {
    setTimeout(() => {
      if (window.scrollY !== 0) {
        return;
      }
      window.scrollTo(0, window.scrollY + 1);
    }, 1000);
  }
}

export type BreakPointScreen = 'sm' | 'md' | 'lg' | 'xl';
export type BreakPointAction = ReturnType<typeof NavActions.toggleClose> | ReturnType<typeof NavActions.toggleOpen>;
