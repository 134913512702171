import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { commonRoutesAmlDesk } from '@app/app-routing.module';
import { KycWorkItemTypesActions } from '@app/store/actions/kyc-work-item-types.actions';
import { User } from '@core/models/user.model';
import { OwnGrant, RoleDisplayName } from '@core/types/grant.types';
import { KycService } from '@kyc/services/kyc.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { selectUserProfile } from '@/auth/store/selectors/auth.selectors';
import { ContractActions } from '@/contract/store/actions/contract.actions';
import { AuthService } from '../../services/auth.service';
import { InactivityService } from '../../services/inactivity.service';
import { AuthActions } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly inactivityService: InactivityService,
    private readonly kycItemTypeService: KycService,
    private readonly store: Store,
  ) {}

  logoutUser = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((action) => {
        // Remove (unauthenticated) user info from local storage.
        localStorage.removeItem('user');
        // TODO: if clearing the full local storage, I see again the onboarding tutorial, information not stored in backend, but on localStorage
        // localStorage.clear();

        // Set the logoutReason, so it can be verified on the logout guard.
        localStorage.setItem('logoutReason', action.logoutReason);
        this.inactivityService.stopTracking();

        // TODO: Handle possible msal errors while logging out the user
        this.authService.logout();
      }),
    );
  };

  /**
   * Logout effect is activated when the logout action is dispatched.
   * Removes user info and redirects the unauthenticated user to the login or logout component according to the logoutReason.
   */
  logout$ = createEffect(this.logoutUser, { dispatch: false });

  logoutReasonEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.resetLogoutReason),
      tap(() => {
        localStorage.removeItem('logoutReason');
      }),
    );
  };

  resetLogoutReason$ = createEffect(this.logoutReasonEffect, { dispatch: false });

  setUserInformationEffect = (): Observable<void> => {
    return this.actions$.pipe(
      ofType(AuthActions.setUserInfo),
      map((action) => {
        try {
          localStorage.setItem('user', JSON.stringify(action.user));
          // Only remove the 'logoutReason' key from localStorage if it exists
          if (localStorage.getItem('logoutReason')) {
            localStorage.removeItem('logoutReason');
          }
          this.inactivityService.startTracking();
        } catch (error: unknown) {
          const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
          throw new Error('An error occurred in setUserInformationEffect: ' + errorMessage);
        }
      }),
    );
  };

  /**
   * This effect is activated when a user logs in successfully, and we were able to fetch his claims.
   * Set user info effect is activated when the "setUserInfo" action is dispatched.
   * Saves user information in local storage and removes logout information if there is some.
   */
  setUserInfo$ = createEffect(this.setUserInformationEffect, { dispatch: false });

  getGrantEffect = (): Observable<Action> => {
    const router = inject(Router);
    return this.actions$.pipe(
      ofType(AuthActions.getGrant),
      mergeMap((action) =>
        this.authService.getGrant(action.accessTokenKerberos).pipe(
          tap((grants) => {
            localStorage.setItem('ownGrants', JSON.stringify(grants));
          }),
          mergeMap((grants: OwnGrant[]) => {
            const hasWorkItemUserGrant = grants.some((grant) => grant.roleDisplayName === RoleDisplayName.WorkItemUser);
            const grantSuccessAction = AuthActions.getGrantSuccess({ grants });

            return this.store.select(selectUserProfile).pipe(
              take(1),
              mergeMap((userProfile: User | undefined) => {
                const workItemTypesRequest = this.createWorkItemTypesRequest(hasWorkItemUserGrant);

                // If has WorkItemUserGrant, skip contract request and redirect to /kyc
                if (hasWorkItemUserGrant) {
                  return concat(
                    of(grantSuccessAction),
                    workItemTypesRequest, // Make the work item types request
                    of(AuthActions.grantLoadingComplete()), // Mark loading as complete
                  ).pipe(
                    tap(() => {
                      void router.navigate([commonRoutesAmlDesk.KycIntroduction]); // Redirect to /kyc after workItemTypes request
                    }),
                  );
                }

                // If no WorkItemUser grant, proceed with both contract and workItemTypes requests
                const contractRequest = this.createContractRequest(userProfile?.kerberosAccountId);
                return concat(
                  of(grantSuccessAction),
                  contractRequest, // Make the contract request
                  of(AuthActions.grantLoadingComplete()), // Mark loading as complete
                );
              }),
            );
          }),
          catchError((error) =>
            concat(of(AuthActions.getGrantFailure({ error: error as Error })), of(AuthActions.grantLoadingComplete())),
          ),
        ),
      ),
    );
  };

  getGrant$ = createEffect(this.getGrantEffect);

  getGrantErrorEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.getGrantFailure),
      tap((action) => console.log('####### getGrantFailure:', action)),
    );
  };

  getGrantFailure$ = createEffect(this.getGrantErrorEffect);

  /**
   * Creates an observable for work item types request based on the given user grant status.
   *
   * @param {boolean} hasWorkItemUserGrant - Indicates whether the user has the grant to access work item types.
   * @return {Observable<Action>} An observable that emits the appropriate work item types actions.
   */
  private createWorkItemTypesRequest(hasWorkItemUserGrant: boolean): Observable<Action> {
    return hasWorkItemUserGrant
      ? this.kycItemTypeService.getWorkItemTypes().pipe(
          map(() => KycWorkItemTypesActions.loadWorkItemTypes()),
          catchError(() => of(KycWorkItemTypesActions.loadWorkItemTypesFailure())),
        )
      : of();
  }

  /**
   * Creates a contract request action based on the provided kerberosAccountId.
   *
   * @param kerberosAccountId - The ID of the Kerberos account. If undefined, no action is dispatched.
   * @return An Observable of an Action which dispatches the load contract action if the kerberosAccountId is provided.
   */
  private createContractRequest(kerberosAccountId: string | undefined): Observable<Action> {
    return kerberosAccountId ? of(ContractActions.loadContract({ kerberosAccountId })) : of();
  }
}
