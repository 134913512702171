import { formatDate } from '@angular/common';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AppDocument, Visibility } from '@core/models/document.model';
import { environment } from '@env/environment';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AppConstants } from '@src/app/app.constants';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FileType } from '../enums/file-type';
import { FileDownloadService } from './file/file-download.service';

export type DmsDocumentInput = {
  path?: string;
  kerberosAccountId: string;
  externalId: string;
  parentCustomerId?: string;
  departmentId?: string;
  title: string;
  type: string;
  scope: string;
  originallyCreatedAt: Date;
  expiresAt?: Date;
  visibility: Visibility;
  createdByUsername: string;
  source: string;
};

export type FilterCriteria = {
  'tags[]'?: string | string[];
  'collection[]'?: string | string[];
  'folders[]'?: string | string[];
};

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends EntityCollectionServiceBase<AppDocument> {
  constructor(
    private readonly httpClient: HttpClient,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    @Inject(LOCALE_ID) private readonly locale: string,
    private downloadService: FileDownloadService,
  ) {
    super('Document', serviceElementsFactory);
  }

  /**
   * Retrieves the documents that belong to the customer.
   * The endpoint requires the kerberosAccountId from the Access token.
   *
   * @returns {Observable<void>} An observable that emits void when the request is successful.
   */
  getCustomerDocuments(): Observable<void> {
    return this.httpClient
      .get(`${environment.config.dms.url}/documents/customer`, {
        params: {
          'tags[]': environment.config.dms.tags,
        },
      })
      .pipe(
        first(),
        map((documents) => {
          this.upsertManyInCache(documents as AppDocument[]);
        }),
      );
  }

  /**
   * Download the document and open it on a new browser tab
   * Works with pdf only.
   */
  async downloadDocument(item: AppDocument): Promise<void> {
    try {
      const url = `${environment.config.dms.url}/documents/${item.id}/content`;

      // const contentType = item.contentType || 'application/pdf';
      const fileName = item.title || item.type; // use 'title', fallback on 'type' if 'title' is not available
      const fileExtension = '.pdf'; // Review: do we use Excel? Since we just share pdf with customer contentType is 'application/pdf'

      this.downloadService.download(url, FileType.Pdf, `${fileName}${fileExtension}`);
    } catch (error: unknown) {
      console.warn(`Failed to download document, due to:`, error);
    }
  }

  /**
   * Creates a new document on DMS
   *
   * @param file the file to be created on the DMS
   * @param fileMetadata the correspondent DMS document metadata
   * @returns observable of HttpEvents reporting the upload progress
   */
  createNewDocument(file: File, fileMetadata: DmsDocumentInput): Observable<HttpEvent<{ id: string }>> {
    const formData = new FormData();
    formData.append('file', file);

    for (const [property, value] of Object.entries(fileMetadata)) {
      if (value) {
        formData.append(property, this.convertToString(value));
      }
    }

    return this.httpClient.post<{ id: string }>(`${environment.config.dms.url}/documents`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  /**
   * Deletes an existing document from DMS
   *
   * @param {string} documentId - The id of the document to be deleted
   * @returns {Observable<boolean>} - Observable confirming the operation succeeded
   */
  deleteExistingDocument(documentId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.config.dms.url}/documents/${documentId}`);
  }

  private convertToString(value: string | Date): string {
    if (typeof value === 'string') {
      return value;
    }

    return formatDate(value, AppConstants.BackendDateFormat, this.locale);
  }
}
