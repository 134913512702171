import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent {
  @Input() icon = '';

  @Input() message = '';

  // eslint-disable-next-line unicorn/prefer-event-target
  @Output() dismiss = new EventEmitter<boolean>();

  onClose(): void {
    this.dismiss.emit(true);
  }
}
