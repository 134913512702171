<div class="px-4 py-6">
  <div class="mb-6">
    <ng-container
      [ngTemplateOutlet]="item"
      [ngTemplateOutletContext]="{
        icon: 'cop-mail',
        link: 'mailto:' + email,
        linkText: email,
        text: emailDescription
      }"
    ></ng-container>
  </div>
  <hr *ngIf="separator" />
  <div class="mt-6">
    <ng-container
      [ngTemplateOutlet]="item"
      [ngTemplateOutletContext]="{
        icon: 'cop-phone',
        link: 'tel:' + phone,
        linkText: phone,
        text: phoneDescription
      }"
    ></ng-container>
  </div>
</div>

<ng-template #item let-icon="icon" let-link="link" let-linkText="linkText" let-text="text">
  <div class="flex items-center gap-3">
    <mat-icon [svgIcon]="icon" class="text-brand"></mat-icon>
    <div>
      <app-link class="text-sm font-medium leading-4.5" linkType="href" [link]="link">
        {{ linkText }}
      </app-link>
      <div class="text-3xs text-gray-cold-10">{{ text }}</div>
    </div>
  </div>
</ng-template>
