import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RiskAnalysisStatus,
  RiskAnalysisTask,
  RiskAnalysisTaskItemData,
  RiskAnalysisTemplate,
  TaskId,
} from '../../risk-analysis-process-models';
import { riskAnalysisProcessFeatureKey, RiskAnalysisProcessState } from '../reducers/risk-analysis-process.reducer';

const selectRiskAnalysisProcessFeature = createFeatureSelector<RiskAnalysisProcessState>(riskAnalysisProcessFeatureKey);

const selectRiskAnalysisRenewalSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => state.ra?.isRenewal as boolean,
);

const selectRiskAnalysisStatusSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => state?.ra?.status as RiskAnalysisStatus,
);

const selectRiskAnalysisTemplateSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => (state.ra?.template as RiskAnalysisTemplate) || {},
);

const selectRiskAnalysisTemplateIdSelector = createSelector(
  selectRiskAnalysisTemplateSelector,
  (template: RiskAnalysisTemplate) => template.id,
);

const selectRiskAnalysisTemplateTasksSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => {
    return state.ra?.template.tasks || [];
  },
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectRiskAnalysisTemplateSingleTaskSelector = ({ taskId }: { taskId: TaskId }) =>
  createSelector(selectRiskAnalysisProcessFeature, (state: RiskAnalysisProcessState) =>
    state.ra?.template.tasks.find((task) => task.id === taskId),
  );

const selectRiskAnalysisTaskInputsSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => state.ra?.tasksInputs as Record<TaskId, RiskAnalysisTaskItemData>,
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectRiskAnalysisSingleTaskInputsSelector = ({ taskId }: { taskId: TaskId }) =>
  createSelector(selectRiskAnalysisProcessFeature, (state: RiskAnalysisProcessState) => state?.ra?.tasksInputs[taskId]);

const selectRiskAnalysisAllTasksFinishedSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState): boolean => {
    const tasks = state.ra?.template.tasks || [];
    const taskInputs = state.ra?.tasksInputs || {};

    return tasks.length > 0 && tasks.length === Object.keys(taskInputs).length;
  },
);

const selectRiskAnalysisTasksProgressSelector = createSelector(
  selectRiskAnalysisTemplateTasksSelector,
  selectRiskAnalysisTaskInputsSelector,
  (templateTasks: RiskAnalysisTask[], taskInputs: Record<TaskId, RiskAnalysisTaskItemData>) => {
    if (templateTasks.length > 1) {
      const copy = [...templateTasks];
      return copy
        .sort((a, b) => a.order - b.order)
        .map((task) => ({
          id: task.id,
          text: task.title,
          route: task.id,
          isFinished: !!taskInputs[task.id]?.submittedDate,
        }));
    }
    return [];
  },
);

export const selectTaskNavigationSelector = createSelector(
  selectRiskAnalysisProcessFeature,
  (state: RiskAnalysisProcessState) => state?.isTaskNavigationOpen,
);

export const RiskAnalysisProcessSelectors = {
  riskAnalysisRenewalSelector: selectRiskAnalysisRenewalSelector,
  riskAnalysisStatusSelector: selectRiskAnalysisStatusSelector,
  riskAnalysisTemplateSelector: selectRiskAnalysisTemplateSelector,
  riskAnalysisTemplateTasksSelector: selectRiskAnalysisTemplateTasksSelector,
  riskAnalysisTemplateSingleTaskSelector: selectRiskAnalysisTemplateSingleTaskSelector,
  riskAnalysisAllTasksFinishedSelector: selectRiskAnalysisAllTasksFinishedSelector,
  riskAnalysisSingleTaskInputsSelector: selectRiskAnalysisSingleTaskInputsSelector,
  riskAnalysisTaskInputsSelector: selectRiskAnalysisTaskInputsSelector,
  riskAnalysisTasksProgressSelector: selectRiskAnalysisTasksProgressSelector,
  riskAnalysisTemplateIdSelector: selectRiskAnalysisTemplateIdSelector,
  taskNavigationOpenStatusSelector: selectTaskNavigationSelector,
};
