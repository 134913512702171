import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconElementComponent } from '../icon-element/icon-element.component';

@Component({
  selector: 'app-badge',
  standalone: true,
  templateUrl: './badge.component.html',
  imports: [IconElementComponent, NgClass],
})
export class BadgeComponent {
  /**
   * @param {string} icon - Name of the registered svg icon
   */
  @Input() icon!: string;

  @Input() extraClasses?: string;

  get badgeStyle(): string {
    return this.extraClasses ?? 'bg-gray-200 text-gray-900';
  }
}
