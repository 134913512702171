import { AppContract } from '@core/models/contract.model';
import { createReducer, on } from '@ngrx/store';
import { ContractActions } from '@/contract/store/actions/contract.actions';

const storageContract = localStorage.getItem('contract');
const contractFromStorage = storageContract ? (JSON.parse(storageContract) as AppContract) : undefined;
export interface ContractState {
  contract: AppContract | undefined;
  error: boolean;
  loading: boolean;
}

export const initialStateContract: ContractState = {
  contract: contractFromStorage,
  error: false,
  loading: false,
};

export const contractReducer = createReducer(
  initialStateContract,
  on(
    ContractActions.loadContract,
    (state): ContractState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    ContractActions.loadContractSuccess,
    (state: ContractState, action): ContractState => ({
      ...state,
      contract: action.contract,
      error: false,
      loading: false,
    }),
  ),
  on(
    ContractActions.loadContractFailure,
    (state: ContractState): ContractState => ({
      ...state,
      contract: undefined,
      error: true,
      loading: false,
    }),
  ),
  on(
    ContractActions.setContractInfoRequested,
    (state: ContractState, action): ContractState => ({
      ...state,
      contract: state.contract ? { ...state.contract, contractInfoRequested: action.contractInfoRequested } : undefined,
    }),
  ),
);

export const contractFeatureKey = 'contract';
