import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthActions, LogoutReason } from '@/auth/store/actions/auth.actions';
import { KerberosAuthActions } from '@/auth/store/actions/kerberos-auth.actions';

export type AuthResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class KerberosAuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private readonly environmentService: EnvironmentService,
  ) {}

  login(accessToken: string): Observable<void> {
    this.store.dispatch(
      KerberosAuthActions.setLoginTokens({
        accessTokenAzure: accessToken,
        accessTokenKerberos: '',
        refreshTokenKerberos: '',
      }),
    );

    return this.http
      .get<AuthResponse>(
        `${this.environmentService.baseAdpHost}/authentication/${this.environmentService.signInEndpoint}`,
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((response: AuthResponse) => {
          // TODO: avoid dispatching many actions
          this.store.dispatch(
            KerberosAuthActions.setLoginTokens({
              accessTokenAzure: accessToken,
              accessTokenKerberos: response.access_token,
              refreshTokenKerberos: response.refresh_token,
            }),
          );
          this.store.dispatch(AuthActions.getGrant({ accessTokenKerberos: response.access_token }));
        }),
      );
  }

  loginWithoutExternalAuth(fixedUuid: string, fixedEmail: string): Observable<void> {
    if (!environment.config.adpHost.signInDev) {
      throw new Error('Debug flag was set but signInDev is not defined. Check your environment.');
    }
    return this.http
      .get<AuthResponse>(
        `${this.environmentService.baseAdpHost}/authentication/${environment.config.adpHost.signInDev}/${fixedUuid}?email=${fixedEmail}`,
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((response: AuthResponse) => {
          // TODO: avoid dispatching many actions
          this.store.dispatch(AuthActions.getGrant({ accessTokenKerberos: response.access_token }));

          this.store.dispatch(
            KerberosAuthActions.setLoginTokens({
              accessTokenAzure: '',
              accessTokenKerberos: response.access_token,
              refreshTokenKerberos: response.refresh_token,
            }),
          );
        }),
      );
  }

  /**
   * Refreshes the authentication token using the provided refresh token.
   *
   * @param refreshToken - The refresh token to use for token refresh.
   * @returns An Observable emitting a partial AuthResponse object.
   * @throws An error if the token response does not contain valid access and refresh tokens.
   */
  refreshToken(refreshToken: string): Observable<Partial<AuthResponse>> {
    return this.http
      .post<AuthResponse>(
        `${this.environmentService.baseAdpHost}/authentication/token`,
        { refresh_token: refreshToken },
        { withCredentials: true },
      )
      .pipe(
        map((response: AuthResponse) => {
          if (!response.access_token || !response.refresh_token) {
            throw new Error('Invalid token response');
          }
          this.store.dispatch(
            KerberosAuthActions.setRefreshTokens({
              accessTokenKerberos: response.access_token,
              refreshTokenKerberos: response.refresh_token,
            }),
          );
          return response;
        }),
        catchError((error: unknown) => {
          this.logout();
          return throwError(() => error);
        }),
      );
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.SessionExpired }));
  }
}
