import { Component, Input } from '@angular/core';

type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'fullScreen';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent {
  /**
   * @param {boolean} center - To center the content-wrapper
   */
  @Input()
  center = false;

  /**
   * @param {boolean} padded - To add standard padding to content-wrapper except for the footer
   */
  @Input()
  padded = false;

  /**
   *
   * @param {string} small - For small centered layouts (Login, SetPwd, MFA)
   * @param {string} medium - For full page layouts (Documents - Dashaboards)
   * @param {string} large - For full page layouts (Documents - Dashaboards)
   * @param {string} xlarge - For full page layouts (Documents - Dashaboards)
   * @param {string} fullScreen - For full page layouts
   */
  @Input()
  variant: Size = 'large';

  @Input() showSupportBtn = false;
}
