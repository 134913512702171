@if (showDownloadDropdown) {
  <div class="flex w-full flex-row-reverse">
    <div class="dropdown dropdown-end">
      <div tabindex="0" class="cursor-pointer rounded-lg bg-gray-300 p-2 text-black">...</div>
      <ul tabindex="0" class="dropdown-content menu rounded-box z-[1] w-42 bg-base-100 p-2 shadow">
        <li><button (click)="downloadCSV($event)" class="m-0 p-2 text-sm hover:bg-accent">{{ downloadCsvText }}</button></li>
      </ul>
    </div>
    <div class="dropdown mr-2">
      <div tabindex="0" class="cursor-pointer rounded-lg bg-gray-300 p-2 text-black">{{ pageSizeSelected }} {{ entriesPerPageText }}</div>
      <ul tabindex="0" class="dropdown-content menu rounded-box z-[1] w-42 bg-base-100 p-2 shadow">
        @for (pageSize of pageSizes; track pageSize) {
          <li><button (click)="onChangePageSize(pageSize)" class="m-0 p-2 text-sm hover:bg-accent">{{ pageSize }}</button></li>
        }
      </ul>
    </div>
  </div>
}
<table mat-table [dataSource]="data$" class="mt-4 w-full">
  <ng-container *ngFor="let column of columns">
    <ng-container matColumnDef="{{ column.key }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="bg-gray-300 text-base font-medium text-gray-1000"
        [attr.data-testid]="column.key"
        scope="colgroup"
      >
        <div class="flex w-full flex-row items-center justify-between">
        <span [ngClass]="{ 'ml-2': column.sortable === false }">
          {{ column.title }}
        </span>
          <app-sort-button
            *ngIf="column.sortable !== false"
            [active]="sortColumn === column.key"
            (sort)="onSort(column.key, $event)"
            class="mr-1"
          ></app-sort-button>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column.template">
          <ng-template
            *ngIf="column.template.getContent && column.template.getContent(element)"
            [ngComponentOutlet]="column.template.component"
            [ngComponentOutletContent]="createContentNode(column.template.getContent(element))"
            [ndcDynamicInputs]="column.template.getInputs ? column.template.getInputs(element) : {}"
            [ndcDynamicOutputs]="column.template.getOutputs ? column.template.getOutputs(element) : {}"
          ></ng-template>
        </ng-container>
        <ng-container *ngIf="!column.template">
          {{ column.callback ? column.callback(element) : element[column.key] }}
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns | extractKey: 'key'"></tr>
  <tr mat-row
      *matRowDef="let row; let i = index; columns: columns | extractKey: 'key'"
      [ngClass]="getEvaluation(row)"
      (click)="clickable && rowClickHandler(row)"
      class="cursor-pointer"
  ></tr>
</table>


