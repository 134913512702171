import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

export type LinkType = 'router' | 'href' | 'click';
@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  standalone: true,
  imports: [RouterModule, NgTemplateOutlet, NgIf],
})
export class LinkComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class')
  classList = '';

  @Input()
  link!: string;

  /**
   * Defines the type of link:
   *
   * Type `'router'` uses `routerLink`
   *
   * Type `'href'` uses `href`
   *
   * Type `'click'` propagates the `click` event
   */
  @Input()
  linkType: LinkType = 'router';

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  linkClick = new EventEmitter<Event>();
}
