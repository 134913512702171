import { Component } from '@angular/core';

// TODO: proper layout if idea accepted, and translations
@Component({
  selector: 'app-unauthorized',
  template: `
    <div class="flex flex-col justify-center items-center h-screen text-center">
      <h1 class="text-2xl font-bold">Unauthorized</h1>
      <p class="mb-4">You do not have access to this page.</p>
      <a href="/" class="text-blue-500 underline">Go back to home</a>
    </div>
  `,
  standalone: true,
})
export class UnauthorizedComponent {}
