<app-link class="flex" [link]="link">
  <img src="/assets/icons/keb-logo.svg" alt="Kerberos logo" />
  <img
    class="ml-2"
    [ngClass]="{
      hidden: mini
    }"
    src="/assets/icons/keb-logo-text.svg"
    alt="Kerberos logo text"
  />
</app-link>
