import { UUID } from 'node:crypto';

type FAQ = {
  'money-laundering': string;
  academy: string;
  'aml-desk': string;
  'risk-analysis': string;
  'kyc-app': string;
};

type PageForm = {
  'kyc-home': string;
  'academy-home': string;
  'contact-home': string;
  faq: FAQ;
  feedback: string;
};

type DocumentManagementSystem = {
  url: string;
  timeout: number;
  tags: string[];
  folders: Folders;
};

type MicrosoftAuthenticationLibrary = {
  clientId: string;
  tenantId: string;
  policy: string;
};

export type LocalizedPageForms = {
  en: PageForm;
  de: PageForm;
};

export type Folders = {
  shared: string;
  realEstate: string;
  tradeInGoods: string;
  noSubscription: string;
  subscription: string;
};

export const debugFlags = ['sentry', 'apiGatewayHeaderToken', 'showVersion', 'noExternalAuth'] as const;
export type DebugFlag = (typeof debugFlags)[number];

export enum EnvironmentsAmlDesk {
  Development = 'development',
  Test = 'test',
  Stage = 'stage',
  Production = 'production',
  Local = 'local',
}

export type Environment = {
  productionBuild: boolean;
  target: EnvironmentsAmlDesk;
  debugFlags: DebugFlag[];
  config: ConfigEnvironment;
};

export type ConfigEnvironment = {
  sentry_dsn: string;
  headerToken?: string;
  featureFlags: {
    url: string;
  };
  dms: DocumentManagementSystem;
  msal: MicrosoftAuthenticationLibrary;
  localDevelopmentUser?: {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: string;
    industry: string;
    kerberosAccountId: string;
    salutation: string;
    preferredLanguage: string;
  };
  sso_url: string;
  adpHost: {
    url: string;
    signIn: string;
    signInDev?: string;
  };
  tenant: UUID;
  forms: LocalizedPageForms;
  registrationGuideDocGoAml: string;
};
