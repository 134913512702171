import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnDestroy {
  query: string = '';

  @Input() placeholder: string = 'Search...';

  destroyed$ = new Subject<void>();

  searchChanges$ = new Subject<string>();

  @Output() search = new EventEmitter<string>();

  constructor() {
    this.searchChanges$
      .pipe(debounceTime(300), takeUntil(this.destroyed$))
      .subscribe((query) => this.search.emit(query));
  }

  onSearch(): void {
    this.searchChanges$.next(this.query);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
