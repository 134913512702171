<div *transloco="let t" [ngClass]="notification.type" class="flex items-center rounded-lg p-2 text-base shadow-md">
  <button *ngIf="notification.icon" class="icon" mat-icon-button>
    <mat-icon class="icon">{{ notification.icon }}</mat-icon>
  </button>
  <span> {{ t(notification.messageKey) }}</span>

  <!-- EMPTY SPACE -->
  <div class="grow"></div>

  <!--  ACTION BUTTON-->
  <button
    (click)="notification.actionCallback()"
    *ngIf="(notification.actionLabel || notification.actionIcon) && notification.actionCallback"
    mat-button
  >
    <mat-icon *ngIf="notification.actionIcon">{{ notification.actionIcon }}</mat-icon>
    {{ notification.actionLabel }}
  </button>
</div>
