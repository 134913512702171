<div class="flex items-center justify-center space-x-1">
  <div
    class="badge h-2 w-3 rounded-full"
    [ngClass]="{
      'bg-gray-500': status !== StatusLevel.Low,
      'bg-green-200': status === StatusLevel.Low
    }"
  ></div>
  <div
    class="badge h-2 w-3 rounded-full"
    [ngClass]="{
      'bg-gray-500': status !== StatusLevel.Medium,
      'bg-yellow-200': status === StatusLevel.Medium
    }"
  ></div>
  <div
    class="badge h-2 w-3 rounded-full"
    [ngClass]="{
      'bg-gray-500': status !== StatusLevel.High,
      'bg-red-200': status === StatusLevel.High
    }"
  ></div>
</div>
