<ng-container *transloco="let t">
  <nav class="flex flex-1">
    <ul class="menu w-full">
      @if (navigationItems$ | async; as filteredNavItems) {
        @for (item of filteredNavItems; track item) {
          <app-nav-item
            (itemClicked)="onItemClicked()"
            [disabled]="disabled"
            [iconOnly]="iconsOnly"
            [route]="item.route"
            [icon]="item.icon"
          >
            {{ t(item.labelKey) }}
          </app-nav-item>

          @if (item.subItems?.length) {
            <div class="pl-4">
              @for (subItem of item.subItems; track subItem) {
                <app-nav-item
                  (itemClicked)="onItemClicked()"
                  [disabled]="disabled"
                  [iconOnly]="iconsOnly"
                  [route]="subItem.route"
                  [icon]="subItem.icon"
                >
                  {{ t(subItem.labelKey) }}
                </app-nav-item>
              }
            </div>
          }
        }
      }

      <app-nav-item
        (click)="signOut()"
        [disabled]="disabled"
        [iconOnly]="iconsOnly"
        data-cy="logout"
        icon="cop-sign-out"
        variant="logout"
      >
        {{ t('navigation.logOut') }}
      </app-nav-item>
    </ul>
  </nav>
</ng-container>
