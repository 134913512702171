import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRipple], [app-ripple]',
  standalone: true,
})
export class RippleDirective {
  private readonly hostElement: HTMLElement;

  @Input()
  appRippleColor?: string;

  @Input()
  appRippleClass?: string;

  @Input()
  appRippleDisabled = false;

  constructor(
    private readonly renderer: Renderer2,
    element: ElementRef<HTMLElement>,
  ) {
    this.hostElement = element.nativeElement;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.appRippleDisabled) {
      const ripple = this.setupRipple();
      this.renderer.removeClass(ripple, 'ripple-animate');
      this.positionRipple(event, ripple);
      this.renderer.addClass(ripple, 'ripple-animate');
    }
  }

  /**
   * Finds already existing ripple or creates new one and adds it to the host element
   */
  setupRipple(): HTMLElement {
    let ripple = this.hostElement.querySelector('.ripple') as HTMLElement;

    // if no ripple has been already created, create new one and add it to the element
    if (!ripple) {
      this.renderer.appendChild(this.hostElement, this.createRippleElement());
      ripple = this.hostElement.querySelector('.ripple') as HTMLElement;
    }

    return ripple;
  }

  /**
   * Positions the ripple where the mouse was clicked
   *
   * @param event The mouse event
   * @param {HTMLElement} ripple The ripple html element
   */
  positionRipple(event: MouseEvent, ripple: HTMLElement): void {
    const x = event.clientX;
    const y = event.clientY;
    const rect = this.hostElement.getBoundingClientRect();
    const offsetX = x - rect.left;
    const offsetY = y - rect.top;

    ripple.style.left = `${offsetX - Number.parseFloat(ripple.style.width) / 2}px`;
    ripple.style.top = `${offsetY - Number.parseFloat(ripple.style.height) / 2}px`;
  }

  /**
   * Creates a ripple HTML element with the proper dimensions and configuration (color)
   */
  createRippleElement(): HTMLElement {
    const rect = this.hostElement.getBoundingClientRect();
    const ripple = document.createElement('span');
    const diameter = Math.max(rect.width, rect.height);

    ripple.style.width = `${diameter}px`;
    ripple.style.height = `${diameter}px`;

    if (this.appRippleClass) {
      this.renderer.addClass(ripple, this.appRippleClass);
    }

    if (this.appRippleColor) {
      ripple.style.backgroundColor = this.appRippleColor;
    }

    this.renderer.addClass(ripple, 'ripple');
    return ripple;
  }
}
