import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { amlDeskRoutes } from '@core/route-map';
import { EnvironmentService } from '@core/services/environment.service';
import { OwnGrant } from '@core/types/grant.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseAuthService {
  constructor(
    readonly httpClient: HttpClient,
    readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Function to log out from the platform
   */
  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    // !IMPORTANT: TODO: this is going to be problematic
    localStorage.setItem('state', amlDeskRoutes.contact);
  }

  /**
   * Retrieves the list of grants associated with the provided access token.
   *
   * @param {string} accessToken - The access token used for authorization.
   * @return {Observable<OwnGrant[]>} An observable containing an array of OwnGrant objects.
   */
  getGrant(accessToken: string): Observable<OwnGrant[]> {
    return this.httpClient.get<OwnGrant[]>(`${this.environmentService.baseAdpHost}/grant/session`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
