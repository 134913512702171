/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import { ConsentManager } from 'klaro/dist/klaro-no-translations-no-css';

type Theme = 'dark' | 'bottom' | 'right';
type Purpose = 'functional-provide-inputs' | 'functional-risk-analysis' | 'functional-improve-UI';

type Service = {
  name: string;
  default?: boolean;
  contextualConsentOnly: boolean;
  purposes: Purpose[];
  optOut?: boolean;
};

type ConfigKlaro = {
  version: number;
  elementID: string;
  styling: {
    theme: Theme[];
  };
  noAutoLoad: boolean;
  htmlTexts: boolean;
  embedded: boolean;
  groupByPurpose: boolean;
  storageMethod: 'cookie';
  cookieExpiresAfterDays: number;
  default: boolean;
  mustConsent: boolean;
  acceptAll: boolean;
  hideDeclineAll: boolean;
  hideLearnMore: boolean;
  noticeAsModal: boolean;
  lang: string;
  translations: LanguageTranslations;
  services: Service[];
};

type TranslationService = {
  title?: string;
  description?: string;
};

type PurposesTranslations = {
  [key: string]: {
    title: string;
  };
};

type Translations = {
  acceptAll: string;
  disableAll: TranslationService;
  acceptSelected: string;
  consentModal: TranslationService;
  consentNotice: {
    description: string;
    learnMore: string;
    changeDescription: string;
  };
  kompassify: TranslationService;
  jotform: TranslationService;
  purposes: PurposesTranslations;
  ok: string;
  poweredBy: string;
  service: {
    purpose: string;
    disableAll: TranslationService;
  };
  save: string;
  close: string;
};

type LanguageTranslations = {
  zz: Translations;
};

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(private readonly translocoService: TranslocoService) {}

  private klaroManager?: ConsentManager;

  private readonly MANAGER_NOT_INITIALIZED = 'Klaro Manager is not yet initialized';

  /**
   * Initializes the necessary services and configuration for the application.
   *
   * This method performs the following actions:
   * 1. Loads the active language using the `translocoService`.
   * 2. Sets up the Klaro consent manager with the appropriate configuration.
   * 3. Assigns the Klaro manager instance to the `klaroManager` property.
   *
   * @return {void} No return value.
   */
  initialize(): void {
    this.translocoService.load(this.translocoService.getActiveLang()).subscribe(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      Klaro.setup(this.getKlaroConfig());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
      this.klaroManager = Klaro.getManager();
    });
  }

  /**
   * Displays the settings dialog for the user. This method invokes the
   * Klaro library’s show function to present the settings dialog, allowing
   * users to adjust their preferences.
   *
   * @return {void} This method does not return any value.
   */
  showSettingsDialog(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    Klaro.show();
  }

  /**
   * Updates the consent status for a specified service and saves the updated consent.
   *
   * @param serviceName The name of the service for which the consent status is being updated.
   * @param consent A boolean value representing the new consent status; true for consent given, false for consent denied.
   * @return void
   * @throws Error if the Klaro manager has not been initialized.
   */
  updateAndSaveConsent(serviceName: string, consent: boolean): void {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }

    this.klaroManager.updateConsent(serviceName, consent);
    this.klaroManager.saveAndApplyConsents('save');
  }

  /**
   * Retrieves the saved consent status for a specified service.
   *
   * @param {string} serviceName - The name of the service for which to get the saved consent status.
   * @return {boolean} The saved consent status of the specified service.
   * @throws {Error} Will throw an error if the klaroManager is not initialized.
   */
  getSavedConsent(serviceName: string): boolean {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }
    // eslint-disable-next-line security/detect-object-injection,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return this.klaroManager.savedConsents[serviceName];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /**
   * Retrieves a service by its name from the Klaro manager.
   *
   * @param {string} serviceName - The name of the service to retrieve.
   * @return {any} The requested service.
   * @throws {Error} If the Klaro manager is not initialized.
   */
  getService(serviceName: string): any {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    return this.klaroManager.getService(serviceName);
  }

  /**
   * Generates and returns the Klaro configuration object.
   * The configuration object controls the behavior and appearance of the Klaro consent management tool.
   *
   * @return {ConfigKlaro} The Klaro configuration object.
   */
  private getKlaroConfig(): ConfigKlaro {
    return {
      version: 1,
      elementID: 'klaro',
      styling: {
        theme: ['dark', 'bottom', 'right'],
      },
      noAutoLoad: false,
      htmlTexts: true,
      embedded: false,
      groupByPurpose: false,
      storageMethod: 'cookie',
      cookieExpiresAfterDays: 180,
      default: false,
      mustConsent: false,
      acceptAll: true,
      hideDeclineAll: true,
      hideLearnMore: false,
      noticeAsModal: false,
      lang: this.translocoService.getActiveLang(),
      translations: this.getTranslations(),
      services: [
        {
          name: 'jotform',
          contextualConsentOnly: false,
          purposes: ['functional-provide-inputs'],
        },
        {
          name: 'kompassify',
          default: false,
          contextualConsentOnly: false,
          purposes: ['functional-improve-UI'],
          optOut: false,
        },
      ],
    };
  }

  /**
   * Retrieves the translations required for the cookie consent modal and notice components.
   * This method constructs an object containing various translated strings for each component and purpose.
   *
   * @return {LanguageTranslations} An object containing all the translated strings.
   */
  private getTranslations(): LanguageTranslations {
    return {
      zz: {
        acceptAll: this.translocoService.translate('cookieConsent.modal.acceptAll'),
        disableAll: {
          title: 'CLose',
          description: 'description',
        },
        acceptSelected: this.translocoService.translate('cookieConsent.modal.acceptSelected'),
        consentModal: {
          title: this.translocoService.translate('cookieConsent.modal.header'),
          description: this.translocoService.translate('cookieConsent.modal.text'),
        },
        consentNotice: {
          description: this.translocoService.translate('cookieConsent.notice.text'),
          learnMore: this.translocoService.translate('cookieConsent.notice.learnMore'),
          changeDescription: this.translocoService.translate('cookieConsent.notice.updated-conditions'),
        },
        kompassify: {
          description: this.translocoService.translate('cookieConsent.modal.descriptions.kompassify'),
        },
        jotform: {
          description: this.translocoService.translate('cookieConsent.modal.descriptions.jotform'),
        },
        purposes: {
          'functional-risk-analysis': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-risk-analysis'),
          },
          'functional-improve-UI': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-improve-UI'),
          },
          'functional-provide-inputs': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-provide-inputs'),
          },
        },
        ok: this.translocoService.translate('cookieConsent.notice.accept'),
        poweredBy: this.translocoService.translate('cookieConsent.poweredBy'),
        service: {
          purpose: this.translocoService.translate('cookieConsent.modal.purpose'),
          disableAll: {
            title: this.translocoService.translate('cookieConsent.modal.disableAll.title'),
            description: this.translocoService.translate('cookieConsent.modal.disableAll.description'),
          },
        },
        save: this.translocoService.translate('cookieConsent.save'),
        close: this.translocoService.translate('cookieConsent.close'),
      },
    };
  }
}
