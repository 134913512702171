import { KycWorkItemTypesActions } from '@app/store/actions/kyc-work-item-types.actions';
import { WorkItemType } from '@kyc/types/kyc.types';
import { createReducer, on } from '@ngrx/store';

export const storageKeyWorkItemTypes = 'workItemTypes';

export const storageWorkItemTypes = sessionStorage.getItem(storageKeyWorkItemTypes);

export const workItemTypesFromStorage = storageWorkItemTypes
  ? (JSON.parse(storageWorkItemTypes) as WorkItemType[])
  : [];

export interface WorkItemTypesAppState {
  workItemTypes: WorkItemType[] | [];
  loading: boolean;
  error: string | undefined;
}

export const initialStateWorkItemTypes: WorkItemTypesAppState = {
  workItemTypes: workItemTypesFromStorage,
  loading: false,
  error: undefined,
};

export const kycWorkItemsTypesReducer = createReducer(
  initialStateWorkItemTypes,
  on(
    KycWorkItemTypesActions.loadWorkItemTypes,
    (state: WorkItemTypesAppState): WorkItemTypesAppState => ({ ...state, loading: true }),
  ),

  on(
    KycWorkItemTypesActions.loadWorkItemTypesSuccess,
    (state: WorkItemTypesAppState, { workItemTypes }): WorkItemTypesAppState => ({
      ...state,
      loading: false,
      workItemTypes,
    }),
  ),

  on(
    KycWorkItemTypesActions.loadWorkItemTypesFailure,
    (state): WorkItemTypesAppState => ({
      ...state,
      loading: false,
    }),
  ),
);

export const kycWorkItemTypesFeatureKey = 'workItemTypes';
