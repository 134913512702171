import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appKey, AppState } from '../reducers/app.reducers';

/**
 * Works as a bridge to access a subproperty from the store
 */
export const selectAppFeature = createFeatureSelector<AppState>(appKey);

export const selectIsSm = createSelector(selectAppFeature, (state: AppState) => state.screen === 'sm');

export const selectIsMd = createSelector(
  selectAppFeature,
  (state: AppState) => state.screen === 'md' || state.screen === 'lg' || state.screen === 'xl',
);

export const selectIsLg = createSelector(
  selectAppFeature,
  (state: AppState) => state.screen === 'lg' || state.screen === 'xl',
);
