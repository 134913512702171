import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Compare two (enum) values by looking up their priority value and then comparing those
 * @param firstVal string, usually enum value for priority lookup
 * @param secondVal string, usually enum value for priority lookup
 * @param sort Metadata that includes priority map and order direction
 * @returns numerical sort value
 */
const compareWithPrio = (firstValue: string, secondValue: string, sort: Sort): -1 | 1 | 0 => {
  const priorityFromEnum1 = sort.prio![firstValue];
  const priorityFromEnum2 = sort.prio![secondValue];

  if (priorityFromEnum1 < priorityFromEnum2) {
    return sort.order === 'asc' ? -1 : 1;
  }
  if (priorityFromEnum1 > priorityFromEnum2) {
    return sort.order === 'asc' ? 1 : -1;
  }

  return 0;
};

/**
 * Checks if the first param is not a value but the second param is
 * @param firstVal Potentially undefined|null
 * @param secondVal Potentially undefined|null
 * @returns boolean
 */
const firstParameterIsUndefinedOrNullButSecondParameterIsDefined = <T>(
  firstValue: T[keyof T],
  secondValue: T[keyof T],
): boolean => {
  return (firstValue === undefined || firstValue === null) && secondValue !== undefined && secondValue !== null;
};

/**
 * Compares two values in cases where either one or both are not a value.
 * Undefined|Null both score lower than any value. If both are Undefined|Null
 * a zero is returned
 * @param firstVal Potentially undefined|null
 * @param secondVal Potentially undefined|null
 * @param sortOrder sort order direction
 * @returns numerical sort value
 */
export const compareUndefinedOrNull = <T>(
  firstValue: T[keyof T],
  secondValue: T[keyof T],
  sortOrder: SortOrder,
): -1 | 1 | 0 => {
  if (firstParameterIsUndefinedOrNullButSecondParameterIsDefined(firstValue, secondValue)) {
    return sortOrder === 'asc' ? -1 : 1;
  } else if (firstParameterIsUndefinedOrNullButSecondParameterIsDefined(secondValue, firstValue)) {
    return sortOrder === 'asc' ? 1 : -1;
  }
  return 0;
};

/**
 * Compares two objects by a key.
 * If any of the values is not a valid value, the comparison is delegated.
 * If a priority is given, the priority comparison will be invoked.
 * In all other cases a regular comparison takes place.
 * @param firstObject
 * @param secondObject
 * @param sort
 * @returns numerical sort value
 */
export const compareByKey = <T>(firstObject: T, secondObject: T, sort: Sort): -1 | 1 | 0 => {
  const key = sort.field as keyof T; // instead this should be an assertion?
  const firstValue = firstObject[key];
  const secondValue = secondObject[key];
  if (firstValue === undefined || firstValue === null || secondValue === undefined || secondValue === null) {
    return compareUndefinedOrNull(firstValue, secondValue, sort.order);
  }

  if (sort.prio === undefined) {
    // eslint-disable-next-line security/detect-object-injection
    if (firstValue < secondValue) {
      return sort.order === 'asc' ? -1 : 1;
    }

    // eslint-disable-next-line security/detect-object-injection
    if (firstValue > secondValue) {
      return sort.order === 'asc' ? 1 : -1;
    }

    return 0;
  } else {
    return compareWithPrio(firstValue as string, secondValue as string, sort);
  }
};

/**
 * Compare two objects by multiple keys in descending importance. Sorts based on first key provided
 * and if the result would be 0 the next key will be compared until no keys remain.
 * @param firstObject
 * @param secondObject
 * @param sortBy Sort[]
 * @returns
 */
export const compareByMultiKeys = <T>(firstObject: T, secondObject: T, sortBy: Sort[]): -1 | 1 | 0 => {
  for (const element of sortBy) {
    const result = compareByKey(firstObject, secondObject, element);
    if (result !== 0) {
      return result;
    }
  }

  return 0;
};

/*
 * Sorts an array according to a given sort obj. Sort object can also be an array.
 * In this case the additional sort obj will be applied if the previous resulted in equilibrium.
 * Usage:
 * value | sortArrayAsync: sort obj
 * Example:
 *   {{ [{id: 2}, {id: 5}] | sortData: {field: 'id', order: 'desc'} }}
 *   {{ [{id: 2, age: 10}, {id: 5, age: 3}] | sortData: [{field: 'id', order: 'desc'},{field: 'age', order: 'asc'}] }}
 */
@Pipe({ name: 'sortArrayAsync' })
export class SortArrayAsyncPipe implements PipeTransform {
  transform<T>(value: Observable<T[]>, sort: Sort | Sort[]): Observable<T[]> {
    return value.pipe(
      map((items) =>
        [...items].sort((firstItem: T, secondItem: T) =>
          Array.isArray(sort)
            ? compareByMultiKeys(firstItem, secondItem, sort)
            : compareByKey(firstItem, secondItem, sort),
        ),
      ),
    );
  }
}

@Pipe({ name: 'sortArray' })
export class SortArrayPipe implements PipeTransform {
  transform<T>(values: T[], sort: Sort | Sort[]): T[] {
    return [...values].sort((firstItem: T, secondItem: T) =>
      Array.isArray(sort) ? compareByMultiKeys(firstItem, secondItem, sort) : compareByKey(firstItem, secondItem, sort),
    );
  }
}

export type SortField = string;
export type SortOrder = 'ASC' | 'DESC' | 'asc' | 'desc' | '';
// prio allows for manual overwrite of comparison conditions (usually Enum values that get assigned a specific number)
export type Sort = { field: SortField; order: SortOrder; prio?: Record<string, number> };
