import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationComponent } from '@core/components/notification/components/notification.component';
import { NotificationType } from '@core/components/notification/models/notification.enum';
import { Notification, NotificationConfig } from '@core/components/notification/models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /* reference to the snackbar component that is created when a notification is shown */
  snackBarRef: MatSnackBarRef<NotificationComponent> | undefined;

  private readonly NOTIFICATION_DURATION = 3000;

  private readonly INDEFINITE = 0;

  private snackBar = inject(MatSnackBar);

  /**
   * Notify the user with a message of the specified type
   * @param type The type of notification
   * @param messageKey The message to display
   * @param config Additional configuration for the notification
   *
   * @remarks This method is a wrapper around the other notification methods.
   * use the specific methods for more control over the notification
   */
  notify(type: NotificationType, messageKey: string, config: NotificationConfig = {}): void {
    if (!messageKey) return;
    switch (type) {
      case NotificationType.Success: {
        return this.success(messageKey, config);
      }
      case NotificationType.Error: {
        return this.error(messageKey, config);
      }
      case NotificationType.Warning: {
        return this.warning(messageKey, config);
      }
      case NotificationType.Info: {
        return this.info(messageKey, config);
      }
      default: {
        return this.info(messageKey, config);
      }
    }
  }

  /**
   * Show a success notification
   */
  success(messageKey: string, config: NotificationConfig): void {
    this.show({
      messageKey,
      type: NotificationType.Success,
      icon: 'check_circle',
      ...config,
    });
  }

  /**
   * Show an error notification
   * @remarks Errors are persistent by default
   */
  error(messageKey: string, config: NotificationConfig): void {
    this.show({
      messageKey,
      type: NotificationType.Error,
      icon: 'error',
      persist: true,
      // actionLabel: 'Close',
      actionIcon: 'close',
      actionCallback: () => {
        this.snackBarRef?.dismiss();
      },
      ...config,
    });
  }

  /**
   * Show a warning notification
   */
  warning(messageKey: string, config: NotificationConfig): void {
    this.show({
      messageKey,
      type: NotificationType.Warning,
      icon: 'warning',
      ...config,
    });
  }

  /**
   * Show an info notification
   */
  info(messageKey: string, config: NotificationConfig): void {
    this.show({
      messageKey,
      type: NotificationType.Info,
      icon: 'info',
      ...config,
    });
  }

  /**
   * Show notification with the specified configuration
   *
   * @remarks Notification Component is used to display the notification message not the default snackbar
   * This method is used internally by the other notification methods
   */
  private show(notification: Notification): void {
    const { duration, persist, ...config } = notification;

    this.snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
      panelClass: ['full-width-snackbar', ...(notification.classNames ?? [])],
      duration: persist ? this.INDEFINITE : duration ?? this.NOTIFICATION_DURATION,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      data: config,
    });
  }
}
