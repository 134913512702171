<div
  class="card flex w-full bg-base-100"
  [class]="classList"
  [ngClass]="{
    'bg-blue-900 text-white': variant === 'primary',
    shadow: shadow === 'small',
    'shadow-lg': shadow === 'large',
    'shadow-xl': shadow === 'x-large',
    'h-full rounded-none': variant === 'offCanvas',
    'rounded-lg': variant !== 'offCanvas'
  }"
>
  <div class="card-header border-b" [ngClass]="{ 'p-4': headerPadding }">
    <ng-content select="header"></ng-content>
  </div>
  <div
    [class]="classListBody"
    class="card-body p-0"
    [ngClass]="{ 'p-4': bodyPadding, 'overflow-y-scroll': variant === 'offCanvas' }"
  >
    <ng-content></ng-content>
  </div>
  <div class="card-footer border-t" [ngClass]="{ 'p-4': footerPadding }">
    <ng-content select="footer"></ng-content>
  </div>
</div>
