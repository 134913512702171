<div class="mx-auto max-w-3xl">
  <h3 class="mb-8 text-xl font-bold">{{ indexSection }} {{ blockHeader }}</h3>
  <div class="grid grid-cols-1 items-stretch justify-items-stretch gap-6 md:grid-cols-2">
    <div class="row-start-2 md:row-start-1">
      <p class="text-base">{{ blockText }}</p>
    </div>
    <div class="relative w-full" style="padding-top: 56.25%">
      <iframe
        [src]="_videoSrc"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        [title]="title"
        class="absolute left-0 top-0 size-full"
      ></iframe>
    </div>
  </div>
  <div class="mt-4 flex flex-col md:flex-row md:justify-end">
    <a *ngIf="downloadButtonLink" (click)="download()">
      <app-button form="link" [iconRight]="true" [inlineIcon]="false" icon="cop-download" class="w-full xl:w-44">
        {{ downloadButtonLabel }}
      </app-button>
    </a>

    <a [href]="registerButtonLink" target="_blank" rel="noopener">
      <app-button variant="primary" class="w-full md:w-44">
        {{ registerButtonLabel }}
      </app-button>
    </a>
  </div>
</div>
