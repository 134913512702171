import { FeaturesAmlDeskWithGrants, OwnGrant, RoleDisplayName } from '@core/types/grant.types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '@/auth/store/reducers/auth.reducers';
import { selectOwnGrants } from '@/auth/store/selectors/auth.selectors';
import { AuthStateTokenAndOwnGrant } from '@/auth/types/auth.types';
import { selectAuthTokens } from './kerberos-auth.selectors';

export const selectAuthTokensAndOwnGrant = (
  feature?: FeaturesAmlDeskWithGrants,
): MemoizedSelector<object, AuthStateTokenAndOwnGrant> =>
  createSelector(selectAuthTokens, selectOwnGrants, (authStateTokens, ownGrants): AuthStateTokenAndOwnGrant => {
    let ownGrant: OwnGrant | undefined;

    if (Array.isArray(ownGrants) && ownGrants.length > 0) {
      // Select the grant based on the feature (service) context
      if (feature === FeaturesAmlDeskWithGrants.KnowYourCustomer) {
        ownGrant = ownGrants.find((grant) => grant.roleDisplayName === RoleDisplayName.WorkItemUser);
      } else if (feature === FeaturesAmlDeskWithGrants.RiskAnalysis) {
        ownGrant = ownGrants.find((grant) => grant.roleDisplayName === RoleDisplayName.RiskAnalysisCustomer);
      }

      // TODO: review: Fallback to BasicUser grant if no functional grant is found, in case no feature pass, check for combinations
      if (!feature) {
        ownGrant =
          ownGrants.find((grant) => grant.roleDisplayName === RoleDisplayName.WorkItemUser) ||
          ownGrants.find((grant) => grant.roleDisplayName === RoleDisplayName.RiskAnalysisCustomer) ||
          ownGrants.find((grant) => grant.roleDisplayName === RoleDisplayName.BasicUser);
      }
    }

    return {
      authStateTokens,
      ownGrant,
    };
  });

export const selectAuthState = (state: { auth: AuthState }): AuthState => state.auth;

export const selectGrantsLoadingComplete = createSelector(
  selectAuthState,
  (authState: AuthState): boolean => authState.grantLoadingComplete,
);
