import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthStateTokens, kerberosAuthTokensFeatureKey } from '@/auth/store/reducers/auth-kerberos.reducers';

export const selectAuthTokens = createFeatureSelector<AuthStateTokens>(kerberosAuthTokensFeatureKey);

/**
 * Uses the Selector to get the accessTokenAzure from the store
 */
export const selectAccessTokenAzure = createSelector(
  selectAuthTokens,
  (state: AuthStateTokens) => state.accessTokenAzure,
);

/**
 * Uses the Selector to get the accessTokenKerberos from the store
 */
export const selectAccessTokenKerberos = createSelector(
  selectAuthTokens,
  (state: AuthStateTokens) => state.accessTokenKerberos as string,
);

/**
 * Uses the Selector to get the refreshTokenKerberos from the store
 */
export const selectRefreshTokenKerberos = createSelector(
  selectAuthTokens,
  (state: AuthStateTokens) => state.refreshTokenKerberos,
);
