import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatusLevel } from './traffi-light.enum';

@Component({
  selector: 'app-traffic-light',
  standalone: true,
  templateUrl: './traffic-light.component.html',
  imports: [NgClass],
})
export class TrafficLightComponent {
  @Input() status!: StatusLevel;

  public StatusLevel = StatusLevel;
}
