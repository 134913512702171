import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { asyncScheduler, Observable } from 'rxjs';
import { selectIsOpened } from '../../../store/selectors/nav.selectors';

@Component({
  selector: 'app-support-floating-button',
  templateUrl: './support-floating-button.component.html',
  styleUrls: ['./support-floating-button.component.scss'],
  animations: [
    trigger('cardAppearAnimation', [
      transition(':enter', [
        style({ width: 0, height: 0, overflow: 'hidden' }),
        animate('150ms', style({ width: '*', height: '*' })),
      ]),
    ]),
  ],
})
export class SupportFloatingButtonComponent implements OnInit {
  showContent = false;

  isOpened$: Observable<boolean> | undefined;

  @Input() email: string = 'service@kerberos-cms.com';

  @Input() phone: string = '+49 221 650 788 60';

  @Input() emailDescription: string | undefined;

  @Input() phoneDescription: string | undefined;

  protected readonly async = asyncScheduler;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.isOpened$ = this.store.select(selectIsOpened);
  }

  toggle(): void {
    this.showContent = !this.showContent;
  }
}
