import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
})
export class ContactCardComponent {
  @Input() email: string | undefined;

  @Input() phone: string | undefined;

  @Input() emailDescription: string | undefined;

  @Input() phoneDescription: string | undefined;

  @Input() separator = true;
}
