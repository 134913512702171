import { createAction, props } from '@ngrx/store';

const setLoginTokens = createAction(
  '[KerberosAuth] Login',
  props<{ accessTokenAzure: string; accessTokenKerberos: string; refreshTokenKerberos: string }>(),
);

const setRefreshTokens = createAction(
  '[Kerberos Auth] Refresh Tokens',
  props<{ accessTokenKerberos: string; refreshTokenKerberos: string }>(),
);

const setLogoutTokens = createAction('[KerberosAuth] Logout Tokens Kerberos');

// FIXME: this action is dispatched, but no reducer nor effect is reaction to it.
const logoutSessionExpired = createAction('[KerberosAuth] Logout Session Expired');

export const KerberosAuthActions = {
  setLoginTokens,
  setLogoutTokens,
  setRefreshTokens,
  logoutSessionExpired,
};
