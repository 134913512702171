import { createFeatureSelector, createSelector } from '@ngrx/store';
import { contractFeatureKey, ContractState } from '@/contract/store/reducers/contract.reducer';

export const selectContract = createFeatureSelector<ContractState>(contractFeatureKey);

export const selectContractLoading = createSelector(selectContract, (state: ContractState) => state.loading);
/**
 * Uses the Selector to get the contract from the store
 */
export const selectActiveContract = createSelector(selectContract, (state: ContractState) =>
  state.contract ? state.contract.contract.serviceActive : undefined,
);

/**
 * Uses the Selector to get the contract from the store
 */
export const selectContractError = createSelector(selectContract, (state: ContractState) => state.error);

export const selectContractCombined = createSelector(
  selectContract,
  selectContractError,
  selectContractLoading,
  (contract, error, loading) => ({
    contract,
    error,
    loading,
  }),
);
