import { createReducer, on } from '@ngrx/store';
import { GlobalLoadingActions, ScreenSizeActions, ToastActions } from '../actions';

export const appKey = 'app';

export interface AppState {
  screen: string;
  activeToast: boolean;
  globalLoading: boolean;
}

export const initialAppState: AppState = {
  screen: 'sm',
  activeToast: localStorage.getItem('timeoutWarning') === null,
  globalLoading: false,
};

export const appReducer = createReducer(
  initialAppState,
  on(ScreenSizeActions.setScreenSize, (state, { screen }): AppState => ({ ...state, screen })),
  on(ToastActions.closeToast, (state): AppState => ({ ...state, activeToast: false })),
  on(ToastActions.openToast, (state): AppState => ({ ...state, activeToast: true })),
  on(GlobalLoadingActions.startLoading, (state): AppState => ({ ...state, globalLoading: true })),
  on(GlobalLoadingActions.stopLoading, (state): AppState => ({ ...state, globalLoading: false })),
);
