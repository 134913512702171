import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationType } from '@core/components/notification/models/notification.enum';
import { NotificationService } from '@core/components/notification/services/notification.service';
import { fromEvent, merge, startWith } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusService {
  isConnected = window.navigator.onLine;

  // Create an observable that emits true when online and false when offline
  private online$ = fromEvent(window, 'online').pipe(
    tap(() => this.notificationService.notify(NotificationType.Success, 'noConnection.notification.online')),
    map(() => true),
  );

  private offline$ = fromEvent(window, 'offline').pipe(
    tap(() =>
      this.notificationService.notify(NotificationType.Error, 'noConnection.notification.offline', {
        actionCallback: undefined,
      }),
    ),
    map(() => false),
  );

  connectionStatus$ = merge(this.online$, this.offline$).pipe(startWith(navigator.onLine));

  constructor(
    private notificationService: NotificationService,
    private destroyReference: DestroyRef,
  ) {
    this.connectionStatus$.pipe(takeUntilDestroyed(this.destroyReference)).subscribe((x) => {
      this.isConnected = x;
    });
  }
}
