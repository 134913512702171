<ng-container *transloco="let t">
  <h5 class="mt-10">{{ t('fileUpload.uploaded') }}</h5>

  <div
    class="mb-6 mt-4 flex gap-x-3 rounded-lg border px-3 py-4"
    *ngIf="uploadStatus !== 'nofile'"
    [ngClass]="{
      'bg-success-200 border-success-100': uploadStatus === 'success',
      'bg-error-200 border-error-100': uploadStatus === 'fail',
      'bg-blue-hover border-brand': uploadStatus === 'ongoing'
    }"
  >
    <div class="flex size-9 shrink-0 items-center justify-center rounded-full bg-base-100">
      <mat-icon mat-list-icon [svgIcon]="uploadStatusIcon" class="size-6 text-primary"></mat-icon>
    </div>
    <div class="text-2xs flex grow flex-col justify-center gap-y-2 font-bold">
      <span *ngIf="uploadStatus === 'ongoing'">{{ t('fileUpload.uploading') }}</span>
      <span *ngIf="uploadStatus === 'fail'">{{ t('fileUpload.failed') }}</span>
      <span *ngIf="uploadStatus === 'success'">{{ filename }}</span>
      <progress
        *ngIf="uploadStatus === 'ongoing'"
        class="progress progress-primary bg-base-100"
        [value]="uploadProgress || 0"
        max="100"
      ></progress>
    </div>
  </div>

  <app-button variant="primary" icon="cop-upload" [iconRight]="true" class="w-full" (click)="uploader.click()">
    <ng-container *ngIf="itIsReplaceUpload">{{ t('fileUpload.replace') }}</ng-container>
    <ng-container *ngIf="!itIsReplaceUpload">{{ t('fileUpload.upload') }}</ng-container>
  </app-button>
  <input #uploader type="file" [accept]="accept" class="absolute opacity-0" (change)="onFileChange($event)" />
</ng-container>
