import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { RiskAnalysisTaskItemData } from '@risk-analysis/risk-analysis-process-models';
import { RiskAnalysisProcessSelectors } from '@risk-analysis/store/selectors/risk-analysis-process.selectors';
import { selectUserProfile } from '@src/app/modules/auth/store/selectors/auth.selectors';
import { Subscription } from 'rxjs';
import { Cookies } from '../cookie-contextual-consent/cookie-contextual-consent.component';

@Component({
  selector: 'app-framed-form',
  templateUrl: './framed-form.component.html',
})
export class FramedFormComponent implements OnInit, OnDestroy {
  // id of the form to be rendered
  @Input()
  formId = '';

  safeUrl: SafeHtml | undefined;

  cookieName = Cookies.Jotform;

  toggleVisible: boolean | undefined;

  kerberosAccountId: string | undefined;

  customerEmail: string | undefined;

  responsibleManager?: { name: string; lastName: string };

  subscription!: Subscription;

  subscriptionToManager!: Subscription;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store,
  ) {}

  get userProperties(): { kerberosAccountId?: string; email?: string } {
    return { kerberosAccountId: this.kerberosAccountId, email: this.customerEmail };
  }

  ngOnInit(): void {
    this.getUserPropertiesFromStore();
    this.getResponsibleManagerFromStore();
    this.sanitizeUrl();
  }

  sanitizeUrl(): void {
    if (this.kerberosAccountId && this.customerEmail) {
      // Construct the URL based on the provided formId, kerberosAccountId and email address
      const constructedUrl = this.responsibleManager
        ? `https://form.jotform.com/${this.formId}?accId=${this.userProperties.kerberosAccountId}&user=${this.userProperties.email}&hasVal=true&firstNameVal=${this.responsibleManager.name}&lastNameVal=${this.responsibleManager.lastName}`
        : `https://form.jotform.com/${this.formId}?accId=${this.userProperties.kerberosAccountId}&user=${this.userProperties.email}&hasVal=false`;
      // Sanitize the URL
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(constructedUrl);
    } else {
      throw new Error('Kerberos Account Id or Email not provided for JotForm url construction');
    }
  }

  onConsentUpdated(consent: boolean): void {
    this.toggleVisible = consent;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscriptionToManager.unsubscribe();
    }
  }

  private getUserPropertiesFromStore(): void {
    // eslint-disable-next-line @ngrx/no-store-subscription
    this.subscription = this.store.select(selectUserProfile).subscribe((user) => {
      if (user) {
        this.kerberosAccountId = user.kerberosAccountId;
        this.customerEmail = user.email;
      }
    });
  }

  private getResponsibleManagerFromStore(): void {
    this.subscriptionToManager = this.store
      .select(RiskAnalysisProcessSelectors.riskAnalysisSingleTaskInputsSelector({ taskId: 'rml' }))
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe((task: RiskAnalysisTaskItemData | undefined) => {
        if (task && Object.keys(task).length > 0 && task.inputs) {
          this.responsibleManager = {
            name: task.inputs?.['firstName'] as string,
            lastName: task.inputs?.['lastName'] as string,
          };
        }
      });
  }
}
