<button
  matRipple
  matRippleColor="rgba(6, 58, 168, 0.25)"
  class="flex flex-col rounded-md border border-transparent px-1.5 py-1 text-gray-700 outline-none focus-visible:border-secondary"
  [ngClass]="{
    'hover:bg-blue-hover': !active
  }"
  (click)="toggleSortDirection($event)"
>
  <mat-icon
    style="width: 12px; height: 7px"
    class="mb-0.5 cursor-pointer text-gray-600"
    [ngClass]="{
      'text-gray-800': active && sortDirection === 'asc'
    }"
    svgIcon="cop-sort-up"
  ></mat-icon>

  <mat-icon
    style="width: 12px; height: 7px"
    class="cursor-pointer text-gray-600"
    [ngClass]="{
      'text-gray-800': active && sortDirection === 'desc'
    }"
    svgIcon="cop-sort-down"
  ></mat-icon>
</button>
