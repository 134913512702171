import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LinkComponent } from '../../elements/link/link.component';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  standalone: true,
  imports: [LinkComponent, NgClass],
})
export class LogoComponent {
  @Input()
  mini = false;

  @Input()
  link = '/';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class')
  @HostBinding('class')
  classList = '';
}
