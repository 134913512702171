import { ApplicationRef, ComponentRef, Injectable, TemplateRef } from '@angular/core';
import { OffCanvasComponent } from '../../components/fragments/off-canvas/off-canvas.component';
import { ComponentInjectionService } from './component-injection-service';

export type OffCanvasObject = {
  title: string;
  template: TemplateRef<{ offCanvasTemplate: string }>;
};

@Injectable({
  providedIn: 'root',
})
export class OffCanvasInjectionService extends ComponentInjectionService<OffCanvasComponent> {
  constructor(protected override readonly applicationReference: ApplicationRef) {
    super(applicationReference);
  }

  initializeComponent(componentReference: ComponentRef<OffCanvasComponent>, offCanvasObject: OffCanvasObject): void {
    componentReference.instance.title = offCanvasObject.title;
    componentReference.instance.templateRef = offCanvasObject.template;
  }
}
