import { Injectable } from '@angular/core';
import { KycWorkItemTypesActions } from '@app/store/actions/kyc-work-item-types.actions';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { KycService } from '@kyc/services/kyc.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class KycWorkItemTypesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly kycService: KycService,
    private readonly kycItemTypeCacheService: KycItemTypeCacheService,
  ) {}

  loadWorkItemTypesEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(KycWorkItemTypesActions.loadWorkItemTypes),
      mergeMap(() => {
        return this.kycService.getWorkItemTypes().pipe(
          map((workItemTypes) => {
            this.kycItemTypeCacheService.setWorkItemTypes(workItemTypes);
            return KycWorkItemTypesActions.loadWorkItemTypesSuccess({ workItemTypes });
          }),
          catchError(() => of(KycWorkItemTypesActions.loadWorkItemTypesFailure())),
        );
      }),
    );
  };

  loadWorkItemTypes$ = createEffect(this.loadWorkItemTypesEffect);
}
