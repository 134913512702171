export type AmlDeskRoutes = {
  dashboard: 'dashboard';
  logout: 'logout';
  documents: 'documents';
  riskAnalysis: 'risk-analysis';
  transparencyRegister: 'transparency-register';
  kycUserCreation: 'kyc-user-creation';
  academy: 'academy';
  contact: 'contact';
  faq: 'faq';
  contractExpired: 'contract-expired';
  kyc: 'kyc';
  unauthorized: 'unauthorized';
};

export const amlDeskRoutes: AmlDeskRoutes = {
  dashboard: 'dashboard',
  logout: 'logout',
  documents: 'documents',
  riskAnalysis: 'risk-analysis',
  transparencyRegister: 'transparency-register',
  kycUserCreation: 'kyc-user-creation',
  academy: 'academy',
  contact: 'contact',
  faq: 'faq',
  contractExpired: 'contract-expired',
  kyc: 'kyc',
  unauthorized: 'unauthorized',
};
