import { createReducer, on } from '@ngrx/store';
import {
  RiskAnalysisProcess,
  RiskAnalysisStatus,
  RiskAnalysisTaskItemData,
  RiskAnalysisTemplate,
  TaskId,
} from '../../risk-analysis-process-models';
import { RiskAnalysisProcessActions } from '../actions/risk-analysis-process.actions';

export const riskAnalysisProcessFeatureKey = 'riskAnalysis';

export interface RiskAnalysisProcessState {
  ra: RiskAnalysisProcess | undefined;
  isTaskNavigationOpen: boolean;
}

export const initialRaState: RiskAnalysisProcessState = {
  ra: {
    isRenewal: false,
    template: {} as RiskAnalysisTemplate,
    tasksInputs: {} as Record<TaskId, RiskAnalysisTaskItemData>,
  } as RiskAnalysisProcess,
  isTaskNavigationOpen: false,
};

export const riskAnalysisProcessReducer = createReducer(
  initialRaState,
  on(
    RiskAnalysisProcessActions.SetRiskAnalysisProcessData,
    (state: RiskAnalysisProcessState, action: { ra: RiskAnalysisProcess }): RiskAnalysisProcessState => ({
      ra: { ...state.ra, ...action.ra },
      isTaskNavigationOpen: state.isTaskNavigationOpen,
    }),
  ),
  on(
    RiskAnalysisProcessActions.SubmitRiskAnalysis,
    (
      state: RiskAnalysisProcessState,
      action: { submittedDate?: Date; status: RiskAnalysisStatus; isRenewal: boolean },
    ): RiskAnalysisProcessState => ({
      ...state,
      ra: { ...state.ra, ...action } as RiskAnalysisProcess,
    }),
  ),
  on(
    RiskAnalysisProcessActions.SetRiskAnalysisTask,
    (
      state: RiskAnalysisProcessState,
      action: { taskInputs: Partial<Record<TaskId, RiskAnalysisTaskItemData>>; status?: RiskAnalysisStatus },
    ): RiskAnalysisProcessState => {
      const newRiskAnalysis = state.ra ? { ...state.ra } : ({} as RiskAnalysisProcess);

      if (state.ra?.tasksInputs) {
        newRiskAnalysis.tasksInputs = { ...state.ra.tasksInputs, ...action.taskInputs };
      }

      if (action?.status) {
        newRiskAnalysis.status = action.status;
      }

      return {
        ...state,
        ra: newRiskAnalysis,
      };
    },
  ),
  on(
    RiskAnalysisProcessActions.IsTaskNavigationOpen,
    (state, { open: isTaskNavigationOpen }): RiskAnalysisProcessState => ({
      ...state,
      isTaskNavigationOpen,
    }),
  ),
);
