import { FlagEnum, ProgressStatus, TranslationKeys, WorkItemStatus } from '../enums/kyc.enum';

type BaseKerberosIcons = 'cop-kyc';
const inProcessIcon = 'cop-kyc-in-process';

export const workItemsStatusIconsConfig: Record<
  WorkItemStatus,
  | `${BaseKerberosIcons}-done`
  | `${BaseKerberosIcons}-tools`
  | `${BaseKerberosIcons}-action-required`
  | `${BaseKerberosIcons}-file-search`
  | `${BaseKerberosIcons}-in-process`
  | `${BaseKerberosIcons}-pause`
  | `${BaseKerberosIcons}-cancelled`
> = {
  [WorkItemStatus.Done]: 'cop-kyc-done',
  [WorkItemStatus.Draft]: 'cop-kyc-tools',
  [WorkItemStatus.ActionExternal]: inProcessIcon,
  [WorkItemStatus.OnHold]: 'cop-kyc-pause',
  [WorkItemStatus.ActionInternal]: inProcessIcon,
  [WorkItemStatus.ActionCustomer]: 'cop-kyc-action-required',
  [WorkItemStatus.Subprocess]: inProcessIcon,
  [WorkItemStatus.ReviewExternal]: inProcessIcon,
  [WorkItemStatus.ReviewInternal]: inProcessIcon,
  [WorkItemStatus.Cancelled]: 'cop-kyc-cancelled',
  [WorkItemStatus.ReviewCustomer]: 'cop-kyc-action-required',
};

export const StatusPriority: Record<ProgressStatus, number> = {
  Closed: 1,
  InProgress: 2,
  Waiting: 3,
  Draft: 4,
} as const;

export const FlagPriority: Record<FlagEnum, number> = {
  // NewDocument: 1,
  // InformationMissing: 2,
  NewComment: 3,
} as const;

export const RiskValuesForPoS = ['1', '2', '3'];

export const RiskValuesForDD = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const RiskValuesForKYC = ['Low', 'Medium', 'High', 'Critical'];

/**
 * Returns the reverse mapping of work item statuses for the given translation key.
 *
 * @param {TranslationKeys} label - The translation key to get the reverse mapping for.
 * @return {WorkItemStatus[]} - The reverse mapping of work item statuses related to the given translation key.
 */
export const getReverseMappingWorkItemStatus = (label: TranslationKeys): WorkItemStatus[] => {
  switch (label) {
    case TranslationKeys.Draft: {
      return [WorkItemStatus.Draft];
    }
    case TranslationKeys.InProcess: {
      return [
        WorkItemStatus.ActionInternal,
        WorkItemStatus.ActionExternal,
        WorkItemStatus.Subprocess,
        WorkItemStatus.ReviewInternal,
        WorkItemStatus.ReviewExternal,
      ];
    }
    case TranslationKeys.ActionRequired: {
      return [WorkItemStatus.ActionCustomer];
    }
    case TranslationKeys.Done: {
      return [WorkItemStatus.Done];
    }
    case TranslationKeys.OnHold: {
      return [WorkItemStatus.OnHold];
    }
    case TranslationKeys.Cancelled: {
      return [WorkItemStatus.Cancelled];
    }
    default: {
      return [];
    }
  }
};

/**
 * Returns the translation key based on the given work item status.
 * @param {WorkItemStatus} status - The work item status.
 * @returns {TranslationKeys | null} - The translation key for the status.
 */
export const getTranslationKeyForStatus = (status: WorkItemStatus): TranslationKeys | null => {
  switch (status) {
    case WorkItemStatus.Draft: {
      return TranslationKeys.Draft;
    }
    case WorkItemStatus.ActionExternal:
    case WorkItemStatus.ActionInternal:
    case WorkItemStatus.Subprocess:
    case WorkItemStatus.ReviewExternal:
    case WorkItemStatus.ReviewInternal: {
      return TranslationKeys.InProcess;
    }
    case WorkItemStatus.ReviewCustomer:
    case WorkItemStatus.ActionCustomer: {
      return TranslationKeys.ActionRequired;
    }
    case WorkItemStatus.Done: {
      return TranslationKeys.Done;
    }
    case WorkItemStatus.OnHold: {
      return TranslationKeys.OnHold;
    }
    case WorkItemStatus.Cancelled: {
      return TranslationKeys.Cancelled;
    }
  }
};
