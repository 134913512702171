import { Component, EventEmitter, Input, Output } from '@angular/core';
import debounce from 'lodash/debounce';

const debounceTime = 300;

export type Option = {
  title: string;
  value: string;
  meta?: string;
  showDatePicker?: boolean;
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  open = false;

  @Input()
  public set activeKeys(values: string[] | undefined) {
    this._activeKeys = new Set(values);
  }

  _activeKeys: Set<string> = new Set();

  @Input() options!: Option[];

  @Input() icon?: string;

  @Input() iconClasses?: string;

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  selected = new EventEmitter<Option>();

  onClick(option: Option): void {
    if (this._activeKeys.has(option.value)) {
      this._activeKeys.delete(option.value);
    } else {
      this._activeKeys.add(option.value);
    }

    this.selected.emit(option);
    this.toggleDebounced();
  }

  toggle = (): void => {
    this.open = !this.open;
  };

  toggleDebounced = debounce(this.toggle.bind(this), debounceTime);
}
