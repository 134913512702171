import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public readonly baseAdpHost = environment.config.adpHost.url;

  public readonly signInEndpoint = environment.config.adpHost.signIn;
}
