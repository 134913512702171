import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-icon-element',
  standalone: true,
  templateUrl: './icon-element.component.html',
  imports: [MatIconModule, NgIf, NgClass],
})
export class IconElementComponent {
  /**
   * @param {string} icon - Name of the registered svg icon
   */
  @Input() icon!: string;

  @Input() extraClasses!: string;
}
