/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import { ConsentManager } from 'klaro/dist/klaro-no-translations-no-css';

type Theme = 'dark' | 'bottom' | 'right';
type Purpose = 'functional-provide-inputs' | 'functional-risk-analysis' | 'functional-improve-UI';

type Service = {
  name: string;
  default?: boolean;
  contextualConsentOnly: boolean;
  purposes: Purpose[];
  optOut?: boolean;
};

type ConfigKlaro = {
  version: number;
  elementID: string;
  styling: {
    theme: Theme[];
  };
  noAutoLoad: boolean;
  htmlTexts: boolean;
  embedded: boolean;
  groupByPurpose: boolean;
  storageMethod: 'cookie';
  cookieExpiresAfterDays: number;
  default: boolean;
  mustConsent: boolean;
  acceptAll: boolean;
  hideDeclineAll: boolean;
  hideLearnMore: boolean;
  noticeAsModal: boolean;
  lang: string;
  translations: LanguageTranslations;
  services: Service[];
};

type TranslationService = {
  title?: string;
  description?: string;
};

type PurposesTranslations = {
  [key: string]: {
    title: string;
  };
};

type Translations = {
  acceptAll: string;
  disableAll: TranslationService;
  acceptSelected: string;
  consentModal: TranslationService;
  consentNotice: {
    description: string;
    learnMore: string;
    changeDescription: string;
  };
  kompassify: TranslationService;
  jotform: TranslationService;
  purposes: PurposesTranslations;
  ok: string;
  poweredBy: string;
  service: {
    purpose: string;
    disableAll: TranslationService;
  };
  save: string;
  close: string;
};

type LanguageTranslations = {
  zz: Translations;
};

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(private readonly translocoService: TranslocoService) {}

  private klaroManager?: ConsentManager;

  private readonly MANAGER_NOT_INITIALIZED = 'Klaro Manager is not yet initialized';

  initialize(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    Klaro.setup(this.getKlaroConfig());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    this.klaroManager = Klaro.getManager();
  }

  showSettingsDialog(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    Klaro.show();
  }

  updateAndSaveConsent(serviceName: string, consent: boolean): void {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }

    this.klaroManager.updateConsent(serviceName, consent);
    this.klaroManager.saveAndApplyConsents('save');
  }

  getSavedConsent(serviceName: string): boolean {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }
    // eslint-disable-next-line security/detect-object-injection,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return this.klaroManager.savedConsents[serviceName];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getService(serviceName: string): any {
    if (!this.klaroManager) {
      throw new Error(this.MANAGER_NOT_INITIALIZED);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    return this.klaroManager.getService(serviceName);
  }

  private getKlaroConfig(): ConfigKlaro {
    return {
      version: 1,
      elementID: 'klaro',
      styling: {
        theme: ['dark', 'bottom', 'right'],
      },
      noAutoLoad: false,
      htmlTexts: true,
      embedded: false,
      groupByPurpose: false,
      storageMethod: 'cookie',
      cookieExpiresAfterDays: 180,
      default: false,
      mustConsent: false,
      acceptAll: true,
      hideDeclineAll: true,
      hideLearnMore: false,
      noticeAsModal: false,
      lang: this.translocoService.getActiveLang(),
      translations: this.getTranslations(),
      services: [
        {
          name: 'jotform',
          contextualConsentOnly: false,
          purposes: ['functional-provide-inputs'],
        },
        {
          name: 'kompassify',
          default: false,
          contextualConsentOnly: false,
          purposes: ['functional-improve-UI'],
          optOut: false,
        },
      ],
    };
  }

  private getTranslations(): LanguageTranslations {
    return {
      zz: {
        acceptAll: this.translocoService.translate('cookieConsent.modal.acceptAll'),
        disableAll: {
          title: 'CLose',
          description: 'description',
        },
        acceptSelected: this.translocoService.translate('cookieConsent.modal.acceptSelected'),
        consentModal: {
          title: this.translocoService.translate('cookieConsent.modal.header'),
          description: this.translocoService.translate('cookieConsent.modal.text'),
        },
        consentNotice: {
          description: this.translocoService.translate('cookieConsent.notice.text'),
          learnMore: this.translocoService.translate('cookieConsent.notice.learnMore'),
          changeDescription: this.translocoService.translate('cookieConsent.notice.updated-conditions'),
        },
        kompassify: {
          description: this.translocoService.translate('cookieConsent.modal.descriptions.kompassify'),
        },
        jotform: {
          description: this.translocoService.translate('cookieConsent.modal.descriptions.jotform'),
        },
        purposes: {
          'functional-risk-analysis': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-risk-analysis'),
          },
          'functional-improve-UI': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-improve-UI'),
          },
          'functional-provide-inputs': {
            title: this.translocoService.translate('cookieConsent.modal.purposes.functional-provide-inputs'),
          },
        },
        ok: this.translocoService.translate('cookieConsent.notice.accept'),
        poweredBy: this.translocoService.translate('cookieConsent.poweredBy'),
        service: {
          purpose: this.translocoService.translate('cookieConsent.modal.purpose'),
          disableAll: {
            title: this.translocoService.translate('cookieConsent.modal.disableAll.title'),
            description: this.translocoService.translate('cookieConsent.modal.disableAll.description'),
          },
        },
        save: this.translocoService.translate('cookieConsent.save'),
        close: this.translocoService.translate('cookieConsent.close'),
      },
    };
  }
}
