<div class="relative mb-6 md:mb-10">
  <label
    class="pb-1"
    [ngClass]="{
      'text-error-100': showError()
    }"
    ><span class="w-full text-xs font-bold"> {{ label }} <ng-container *ngIf="required">*</ng-container></span></label
  >
  <input
    class="input input-bordered h-11 w-full max-w-[288px] border-base-content hover:border-gray-900 focus:border-brand focus-visible:ring-0"
    [ngClass]="{
      'border-error-100': showError()
    }"
    [type]="type"
    [placeholder]="placeholder"
    [value]="value"
    (input)="internalOnChange($event)"
    [disabled]="disabled"
    (blur)="onTouched()"
  />
  <p *ngIf="showError()" class="absolute mt-1 text-3xs text-error-100">{{ fieldErrorMessage }}</p>
</div>
