<!-- link property needs to be added here when we have the list -->
<a *ngIf="linkType === 'router'" [routerLink]="link" class="inline-flex items-center text-brand" [class]="classList">
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</a>
<a *ngIf="linkType === 'href'" [href]="link" class="inline-flex items-center text-brand" [class]="classList">
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</a>
<a
  *ngIf="linkType === 'click'"
  (click)="linkClick.emit()"
  class="inline-flex cursor-pointer items-center text-brand"
  [class]="classList"
>
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</a>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
