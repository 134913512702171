<div
  *ngIf="loading$ | async"
  class="fixed bottom-0 left-0 z-50 flex size-full items-center justify-center bg-gray-cold-10"
>
  <svg class="size-20" viewBox="25 25 50 50">
    <circle class="stroke-current text-gray-400" cx="50" cy="50" r="20" fill="none" stroke-width="5"></circle>
    <circle class="animated stroke-current text-secondary" cx="50" cy="50" r="20" fill="none" stroke-width="5"></circle>
  </svg>
</div>
<ng-content></ng-content>
