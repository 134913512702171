import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { amlDeskRoutes } from '@core/route-map';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';

// Todo: maybe can be a child route and the auth module be lazy loaded from the main app.routing
const routes = [
  {
    path: amlDeskRoutes.logout,
    component: LogoutPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

/** Auth Routes */
export class AuthRoutingModule {}
