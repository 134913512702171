import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { amlDeskRoutes } from '@core/route-map';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { selectIsUserLoggedIn } from '@/auth/store/selectors/auth.selectors';
import { selectContractCombined } from '@/contract/store/selectors/contract.selectors';

@Injectable({
  providedIn: 'root',
})
export class ContractActiveGuard {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  /**
   * Check if the current user is authorized to access the specified route.
   *
   * @return {Observable<boolean>} - An Observable that emits a boolean indicating whether the user is authorized or not.
   */
  canActivate(): Observable<boolean> {
    return this.store.select(selectIsUserLoggedIn).pipe(
      switchMap((isLogged) => {
        if (!isLogged) {
          void this.router.navigate([amlDeskRoutes.logout]);
          return of(false);
        }
        return this.waitForGrantsAndContractToLoad();
      }),
      catchError((error) => {
        console.warn('Error fetching user login status:', error);
        void this.router.navigate([amlDeskRoutes.logout]);
        return of(false);
      }),
    );
  }

  /**
   * Waits for the grants and contracts to be fully loaded.
   *
   * This method monitors the contract loading state and ensures that the contract data is
   * successfully retrieved before allowing further processing. If the contract is invalid
   * or an error occurs, it redirects the user to appropriate routes.
   *
   * @return An Observable that emits a boolean value indicating whether the contract is valid.
   */
  private waitForGrantsAndContractToLoad(): Observable<boolean> {
    // Wait until both grants and contracts are fully loaded
    return this.store.select(selectContractCombined).pipe(
      filter(({ loading }) => !loading), // Wait until contract loading completes
      take(1),
      switchMap(({ contract, error }) => {
        if (error || !contract || contract.contract?.contract?.serviceActive === false) {
          void this.router.navigate([amlDeskRoutes.contractExpired]); // Redirect to contract expired page
          return of(false);
        }
        return of(true); // Allow access to the dashboard if the contract is valid
      }),
      catchError((error) => {
        console.warn('Error fetching contract:', error);
        void this.router.navigate([amlDeskRoutes.logout]);
        return of(false);
      }),
    );
  }
}
