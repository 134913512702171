import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input()
  title = 'Send Data To Kerberos';

  @Input()
  text = 'By sending the data to Kerberos we can provide you your Risk Analysis and all other documents.';

  @Input()
  cancel = 'Cancel';

  @Input()
  confirm = 'Submit';

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  confirmedClick = new EventEmitter<Event>();

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  dismiss = new EventEmitter<Event>();

  close(): void {
    this.dismiss.emit();
  }

  confirmClick(): void {
    this.confirmedClick.emit();

    this.dismiss.emit();
  }
}
