<app-button
  *ngIf="!showContent"
  form="circle"
  variant="inverted"
  icon="cop-support-fab"
  [iconOnly]="true"
  [inlineIcon]="true"
  class="fixed bottom-16 shadow-xl hover:border-gray-700 hover:bg-gray-700 hover:shadow-xl focus-visible:outline-none focus-visible:ring focus-visible:ring-offset-2 lg:right-8"
  (click)="toggle()"
></app-button>

<app-card
  *ngIf="showContent"
  [style.--side-navbar-width]="(isOpened$ | async) ? '248px' : '80px'"
  @cardAppearAnimation
  class="fixed bottom-16"
  shadow="x-large"
  [headerPadding]="false"
  [bodyPadding]="false"
  [footerPadding]="false"
>
  <app-contact-card
    [phone]="phone"
    [phoneDescription]="phoneDescription"
    [email]="email"
    [emailDescription]="emailDescription"
  ></app-contact-card>

  <footer class="flex h-12 items-center bg-gray-cold-10 text-white">
    <mat-icon svgIcon="cop-support-fab" class="size-12"></mat-icon>
    <mat-icon svgIcon="cop-close-x" class="ml-auto mr-2 size-8 cursor-pointer" (click)="toggle()"></mat-icon>
  </footer>
</app-card>
