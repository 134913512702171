<app-card [bodyClass]="'border-2 rounded-lg border-brand'">
  <div class="flex justify-between">
    <div class="mx-auto flex space-x-1">
      <mat-icon class="min-w-4 scale-125 text-brand" svgIcon="{{ icon }}"></mat-icon>
      <span class="text-sm">
        {{ message }}
      </span>
    </div>
    <div>
      <mat-icon
        style="cursor: pointer"
        class="scale-125 text-brand"
        (click)="onClose()"
        svgIcon="cop-close-x"
      ></mat-icon>
    </div>
  </div>
</app-card>
