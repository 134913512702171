import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, take } from 'rxjs';
import { FileType, mimeType } from '../../enums/file-type';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  /**
   * Regex to extract filename from Content-Disposition header
   * Example: Content-Disposition: attachment; filename="example.pdf"
   */
  private static FILE_NAME_REGEX: RegExp = /filename="?(?<filename>[^";]+)"?$/;

  private static ILLEGAL_FILENAME_CHARS_REGEX = /["%*/:<>?\\|]/g;

  constructor(private http: HttpClient) {}

  /**
   * Download file from given url and save it with given filename
   * @param url - url of the file to download
   * @param filename - optional filename to save the file with
   * @param fileType - optional file type to convert the blob to the appropriate MIME type
   *   *
   * @notes
   * As per MDN docs, Based on the current implementation, browsers won't actually read the bytestream of a file to determine its media type.
   * It is assumed based on the file extension;
   *
   * @remarks
   * if you want to download a file from the Google bucket, you would typically have the filename with extension in the url and it would be enough to download the file.
   * But if the url does not have the filename with extension, you can pass the fileType and the filename.
   */
  download(url: string, fileType?: FileType, filename?: string): void {
    this.fetchFile(url).subscribe((response: HttpResponse<Blob> | undefined) => {
      if (!response) {
        return;
      }

      let blob: Blob = response.body as Blob;

      // If a file type is provided, convert the blob to the appropriate MIME type
      blob = this.convertBlob(blob, fileType);

      filename = filename || this.extractFileName(response, url);
      filename = this.sanitizeFileName(filename);
      this.initiateDownload(blob);
    });
  }

  /**
   * Triggers the download of a CSV file.
   *
   * @param {string} csv - The CSV content as a string.
   * @return {void}
   */
  triggerDownloadCsv(csv: string): void {
    const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });
    this.initiateDownload(blob);
  }

  /**
   * Downloads a file from the given URL and returns an Observable that emits the HTTP response containing the file as a Blob.
   *
   * @param {string} url - The URL from which to fetch the file.
   * @return {Observable<HttpResponse<Blob>>} An Observable that emits the HTTP response containing the file as a Blob.
   */
  private fetchFile(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      take(1),
      catchError((error) => {
        console.error('Error downloading the file:', error);
        return of();
      }),
    );
  }

  /**
   * Converts the given Blob to a new Blob with the specified file type if provided.
   *
   * @param {Blob} blob - The original Blob to be converted.
   * @param {FileType | undefined} fileType - The desired file type to convert the Blob to. If undefined, the original Blob is returned.
   * @return {Blob} - The converted Blob with the specified file type, or the original Blob if no file type is provided.
   */
  private convertBlob(blob: Blob, fileType: FileType | undefined): Blob {
    if (fileType && mimeType[fileType]) {
      blob = new Blob([blob], {
        type: mimeType[fileType],
      });
    }
    return blob;
  }

  /**
   *
   * Initiates a file download using the provided Blob object and filename.
   *
   * On Chrome, creating and then assigning a URL to a new window could potentially be blocked
   * due to popup blocking mechanisms or other security features.
   * Instead of a new window, this function creates a temporary anchor link element
   * with a download attribute, which initiates a file download when clicked programmatically.
   * After the download is initiated, the temporary URL is revoked to free up resources.
   *
   * @param blob The Blob object representing the content to be downloaded.
   */
  private initiateDownload(blob: Blob): void {
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    const downloadURL = URL.createObjectURL(blob);
    this.openInNewTab(downloadURL);
    // eslint-disable-next-line n/no-unsupported-features/node-builtins
    URL.revokeObjectURL(downloadURL);
  }

  /**
   * Open a provided file URL in a new browser tab and initiate download.
   *
   * @param {string} fileUrl - The URL of the file to be downloaded.
   * @return {void}
   */
  private openInNewTab(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  /**
   * Extract the filename from the HTTP response headers or URL.
   * @param response - The HTTP response containing the file.
   * @param url - The downloadURL of the file.
   * @returns The extracted or inferred filename.
   */
  private extractFileName(response: HttpResponse<Blob>, url: string): string {
    const DEFAULT_FILENAME = 'download';

    const contentDisposition = response.headers.get('Content-Disposition');

    // If Content-Disposition header is not present, extract filename from URL
    if (!contentDisposition) {
      return url.split('/').pop() || DEFAULT_FILENAME;
    }
    // extract filename from Content-Disposition header
    const match = contentDisposition.match(FileDownloadService.FILE_NAME_REGEX);
    if (match && match.groups && match.groups['filename']) {
      return match.groups['filename'];
    }
    return DEFAULT_FILENAME;
  }

  /**
   * Sanitize the filename by replacing illegal characters.
   * @param fileName - The filename to sanitize.
   * @returns The sanitized filename.
   */
  private sanitizeFileName(fileName: string): string {
    return fileName.replace(FileDownloadService.ILLEGAL_FILENAME_CHARS_REGEX, '-');
  }
}
