import { User } from '@core/models/user.model';
import { OwnGrant } from '@core/types/grant.types';
import { createReducer, on } from '@ngrx/store';
import { AuthActions, LogoutReason } from '../actions/auth.actions';

// FIXME: why are we not using one json for the entire state?? -> We can modify to have a single item on local
const storageItem = localStorage.getItem('user');
const userFromStorage = storageItem ? (JSON.parse(storageItem) as User) : undefined;
const grantsStorageItem = localStorage.getItem('ownGrants');
const grantsFromStorage = grantsStorageItem ? (JSON.parse(grantsStorageItem) as OwnGrant[]) : undefined;
const storageItemLogoutReason = localStorage.getItem('logoutReason');
const logoutReasonFromStorage = storageItemLogoutReason ? (storageItemLogoutReason as LogoutReason) : undefined;
const grantLoadingComplete = localStorage.getItem('grantLoadingComplete');

// TODO: it was never set localStorage.setItem, localStorage.getItem('amlAuth') -> null
// const allStorage = localStorage.getItem('amlAuth');
// const amlAuthFromStorage = allStorage ? (JSON.parse(allStorage) as AuthState) : undefined;

export const initialAuthState: AuthState = {
  user: userFromStorage,
  ownGrants: grantsFromStorage,
  errorMessage: undefined,
  logoutReason: logoutReasonFromStorage,
  grantLoadingComplete: grantLoadingComplete === 'true',
};

export const authReducer = createReducer(
  initialAuthState,
  on(
    AuthActions.setUserInfo,
    (state: AuthState, action: { user: User }): AuthState => ({
      ...state,
      user: action.user,
      logoutReason: undefined,
    }),
  ),

  on(
    AuthActions.logout,
    (state: AuthState, action: { logoutReason: LogoutReason }): AuthState => ({
      ...state,
      user: undefined,
      errorMessage: undefined,
      logoutReason: action.logoutReason,
    }),
  ),

  on(
    AuthActions.loginFailure,
    (state: AuthState, action: { errorMessage: string }): AuthState => ({
      ...state,
      errorMessage: action.errorMessage,
    }),
  ),

  on(
    AuthActions.getGrantSuccess,
    (state: AuthState, action): AuthState => ({
      ...state,
      ownGrants: action.grants,
    }),
  ),

  on(
    AuthActions.getGrantFailure,
    (state: AuthState, action): AuthState => ({
      ...state,
      ownGrants: undefined, // Clear grants on failure
      errorMessage: action.error.message,
    }),
  ),

  on(AuthActions.grantLoadingComplete, (state: AuthState): AuthState => {
    localStorage.setItem('grantLoadingComplete', 'true');
    return {
      ...state,
      grantLoadingComplete: true,
    };
  }),
);

export const authFeatureKey = 'auth';

export interface AuthState {
  user: User | undefined;
  ownGrants: OwnGrant[] | undefined;
  errorMessage: string | undefined;
  logoutReason: LogoutReason | undefined;
  grantLoadingComplete: boolean;
}
