<app-card
  class="fixed right-0 top-0 z-50 h-screen w-screen shadow-md md:top-16 md:z-9 md:max-w-md lg:top-0"
  variant="offCanvas"
>
  <header class="flex h-8">
    <h3 class="m-0 inline-block grow text-xl font-bold text-black">{{ title }}</h3>
    <mat-icon
      mat-list-icon
      svgIcon="cop-close-x"
      class="ml-auto w-8 max-w-[32px] cursor-pointer text-brand"
      [inline]="true"
      (click)="close()"
    ></mat-icon>
  </header>
  <ng-container *ngTemplateOutlet="templateRef"></ng-container>
</app-card>
