import { Component, Input } from '@angular/core';

export const cardShadowSizes = ['', 'small', 'large', 'x-large'] as const;
export type ShadowSize = (typeof cardShadowSizes)[number];

export const cardVariants = ['', 'primary', 'offCanvas'] as const;
export type CardVariant = (typeof cardVariants)[number];

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input()
  classList = '';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('bodyClass')
  classListBody = '';

  @Input()
  headerPadding = true;

  @Input()
  footerPadding = true;

  @Input()
  bodyPadding = true;

  @Input() variant: CardVariant = '';

  @Input() shadow: ShadowSize = '';
}
