import { createReducer, on } from '@ngrx/store';
import { NavActions } from '@/shared/store/actions/nav.actions';

export const navFeatureKey = 'navigation';

export interface NavState {
  opened: boolean;
}

export const initialNavState: NavState = {
  opened: false,
};

export const navReducer = createReducer(
  initialNavState,
  on(NavActions.toggleOpen, (state): NavState => ({ ...state, opened: true })),
  on(NavActions.toggleClose, (state): NavState => ({ ...state, opened: false })),
);
