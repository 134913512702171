import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthActions } from '@/auth/store/actions/auth.actions';
import { KerberosAuthActions } from '@/auth/store/actions/kerberos-auth.actions';

@Injectable()
export class KerberosAuthEffects {
  constructor(private readonly actions$: Actions) {}

  setKerberosTokensEffect = (): Observable<void> => {
    return this.actions$.pipe(
      ofType(KerberosAuthActions.setLoginTokens),
      map((action) => this.saveKerberosTokensInformation(action)),
    );
  };

  /**
   * This effect is activated when a user logs in successfully, and we were able to fetch his claims.
   * Set user info effect is activated when the "setUserInfo" action is dispatched.
   * Saves user information in local storage and removes logout information if there is some.
   */
  setKerberosTokens$ = createEffect(this.setKerberosTokensEffect, { dispatch: false });

  setRefreshTokensEffect = (): Observable<void> => {
    return this.actions$.pipe(
      ofType(KerberosAuthActions.setRefreshTokens),
      map((action) => this.saveKerberosTokensInformation(action)),
    );
  };

  setRefreshTokens$ = createEffect(this.setRefreshTokensEffect, { dispatch: false });

  logoutSessionExpiredEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      map(() => {
        return KerberosAuthActions.setLogoutTokens();
      }),
    );
  };

  logoutSessionExpired$ = createEffect(this.logoutSessionExpiredEffect, { dispatch: false });

  /**
   * Saves Kerberos tokens to local storage.
   *
   * @param {Object} action - An object containing the Kerberos tokens.
   * @param {string} action.accessTokenKerberos - The access token for Kerberos.
   * @param {string} action.refreshTokenKerberos - The refresh token for Kerberos.
   * @return {void} This method does not return a value.
   */
  private saveKerberosTokensInformation(action: { accessTokenKerberos: string; refreshTokenKerberos: string }): void {
    try {
      localStorage.setItem('accessTokenKerberos', action.accessTokenKerberos);
      localStorage.setItem('refreshTokenKerberos', action.refreshTokenKerberos);
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      throw new Error(`An error occurred while saving Kerberos tokens: ${errorMessage}`);
    }
  }
}
