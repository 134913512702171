import { createAction, props } from '@ngrx/store';
import {
  RiskAnalysisProcess,
  RiskAnalysisStatus,
  RiskAnalysisTaskInputs,
  RiskAnalysisTaskItemData,
  TaskId,
} from '../../risk-analysis-process-models';

const SetRiskAnalysisProcessData = createAction(
  '[RiskAnalysis] Update Risk Analysis Template and status',
  props<{ ra: RiskAnalysisProcess }>(),
);

const SetRiskAnalysisTask = createAction(
  '[RiskAnalysis] Set Risk Analysis Task',
  props<{ taskInputs: Partial<Record<TaskId, RiskAnalysisTaskItemData>>; status?: RiskAnalysisStatus }>(),
);

const SubmitRiskAnalysis = createAction(
  '[RiskAnalysis] Submit Risk Analysis',
  props<{ submittedDate?: Date; status: RiskAnalysisStatus; isRenewal: boolean }>(),
);

const SubmitTask = createAction(
  '[RiskAnalysis] Submit Task',
  props<{ taskId: string; templateId: string; taskInputs: Partial<RiskAnalysisTaskInputs> }>(),
);

const SubmitDataToKerberos = createAction('[Kerberos] Submit Data');

const IsTaskNavigationOpen = createAction('[Task Navigation] Set Open Status', props<{ open: boolean }>());

export const RiskAnalysisProcessActions = {
  SetRiskAnalysisProcessData,
  SubmitRiskAnalysis,
  SetRiskAnalysisTask,
  SubmitTask,
  SubmitDataToKerberos,
  IsTaskNavigationOpen,
};
