import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { EnvironmentService } from '@core/services/environment.service';
import { BaseAuthService } from './base-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseAuthService {
  constructor(
    override readonly httpClient: HttpClient,
    private readonly msalService: MsalService,
    override readonly environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService);
  }

  /**
   * Function to log out from the platform
   */
  override logout(): void {
    super.logout();
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  }
}
