import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule, Provider } from '@angular/core';
import { AppConstants } from '@app/app.constants';
import { environment } from '@env/environment';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { lastValueFrom, Observable } from 'rxjs';
import { Language } from './core/enums/languages.enum';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

// SEE: https://ngneat.github.io/transloco/docs/recipes/prefetch/
export function preloadTranslations(transloco: TranslocoService) {
  return async (): Promise<Translation> => {
    transloco.setDefaultLang(AppConstants.DefaultLanguage);
    await lastValueFrom(transloco.load(Language.English));
    return lastValueFrom(transloco.load(Language.Deutsch));
  };
}

export const loadTranslations: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: preloadTranslations,
  deps: [TranslocoService],
};

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: AppConstants.AvailableLanguages,
        defaultLang: AppConstants.DefaultLanguage,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.productionBuild,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
