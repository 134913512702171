import { Injectable } from '@angular/core';
import { storageKeyWorkItemTypes } from '@app/store/reducers/kyc-work-item-types.reducer';
import { WorkItemType } from '@kyc/types/kyc.types';
import { BehaviorSubject } from 'rxjs';

// TODO: candidate to be the abstraction over the selected storage method, localStorage, or sessionStorage
@Injectable({
  providedIn: 'root',
})
export class KycItemTypeCacheService {
  private storageKey = storageKeyWorkItemTypes;

  // eslint-disable-next-line unicorn/no-null
  private workItemTypesSubject = new BehaviorSubject<WorkItemType[] | null>(null);

  public workItemTypes$ = this.workItemTypesSubject.asObservable();

  constructor() {
    this.initializeWorkItemTypes();
  }

  /**
   * Retrieves the work item types from session storage.
   * @returns {WorkItemType[] | undefined} - An array of work item types if found in session storage, otherwise undefined.
   */
  getWorkItemTypes(): WorkItemType[] | undefined {
    const data = sessionStorage.getItem(this.storageKey);
    if (!data) {
      return;
    }

    try {
      return JSON.parse(data) as WorkItemType[];
    } catch (error) {
      console.error('Failed to parse work item types from session storage:', error);
      return;
    }
  }

  /**
   * Initializes the work item types by retrieving stored work item types
   * and updating the workItemTypesSubject if available.
   *
   * @return {void} No return value.
   */
  private initializeWorkItemTypes(): void {
    const storedWorkItemTypes = this.getWorkItemTypes();
    if (storedWorkItemTypes) {
      this.workItemTypesSubject.next(storedWorkItemTypes);
    }
  }

  /**
   * Sets the work item types in session storage
   * @param {WorkItemType[]} workItemTypes - an array of work item types to be stored in session storage
   * @return {void}
   */
  setWorkItemTypes(workItemTypes: WorkItemType[]): void {
    try {
      sessionStorage.setItem(this.storageKey, JSON.stringify(workItemTypes));
      this.workItemTypesSubject.next(workItemTypes);
    } catch (error) {
      console.error('Failed to set work item types in session storage:', error);
    }
  }

  clearWorkItemTypes(): void {
    sessionStorage.removeItem(this.storageKey);
    // eslint-disable-next-line unicorn/no-null
    this.workItemTypesSubject.next(null);
  }
}
