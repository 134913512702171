import { WorkItemType } from '@kyc/types/kyc.types';
import { createAction, props } from '@ngrx/store';

const loadWorkItemTypes = createAction('[Work Item Types] Load Work Item Types');

const loadWorkItemTypesSuccess = createAction(
  '[Work Item Types] Load Work Item Types Success',
  props<{ workItemTypes: WorkItemType[] }>(),
);

const loadWorkItemTypesFailure = createAction('[Work Item Types] Load Work Item Types Failure');

export const KycWorkItemTypesActions = {
  loadWorkItemTypes,
  loadWorkItemTypesSuccess,
  loadWorkItemTypesFailure,
};
