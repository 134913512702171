import { UUID } from 'node:crypto';

export enum FeaturesAmlDeskWithGrants {
  RiskAnalysis = 'RiskAnalysis',
  KnowYourCustomer = 'KnowYourCustomer',
}

// TODO: add to a common library
export const enum RoleDisplayName {
  // Default role that every user should have, who can access one of the frontends.
  BasicUser = 'BasicUser',

  // Default role that every API enabled user should have.
  BasicApiUser = 'BasicApiUser',

  // KERBEROS employee
  InternalUser = 'Internal user',

  // Customer role for Risk Analysis
  RiskAnalysisCustomer = 'RiskAnalysisCustomer',

  // Customer role for any user working with Work Items
  WorkItemUser = 'WorkItemUser',

  // Role for the API user accessing from Salesforce
  SalesforceApi = 'SalesforceApi',

  // Role for the administrator managing the roles (globally).
  RoleAdministrator = 'RoleAdministrator',

  // Role for the administrator managing the grants (on tenant-level).
  GrantAdministrator = 'GrantAdministrator',

  // Role for the administrator of the system (globally).
  SystemAdministrator = 'SystemAdministrator',
}

export type OwnGrant = {
  tenantId: UUID;
  organizationId?: UUID;
  roleId: UUID;
  roleDisplayName: RoleDisplayName;
  organizationDisplayName: string;
  readableId: string;
  userId: UUID;
};
