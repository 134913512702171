import { ApplicationRef, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogObject } from '@/shared/services/component-injection/confirmation-dialog-injection.service';
import { OffCanvasObject } from '@/shared/services/component-injection/off-canvas-injection.service';

export interface Dismissible {
  dismiss: Subject<Event>;
}

export type InitializationObject = ConfirmationDialogObject | OffCanvasObject;

@Injectable({
  providedIn: 'root',
})
export abstract class ComponentInjectionService<T extends Dismissible> {
  protected constructor(protected readonly applicationReference: ApplicationRef) {}

  show(
    initializationObject: InitializationObject,
    componentToLoad: Type<T>,
    viewContainerReference: ViewContainerRef,
  ): ComponentRef<T> {
    const componentReference = viewContainerReference.createComponent(componentToLoad);
    this.initializeComponent(componentReference, initializationObject);
    Object.assign(componentReference.instance, initializationObject);
    componentReference.instance.dismiss.subscribe(() => this.dismiss(componentReference));
    return componentReference;
  }

  private dismiss(componentReference: ComponentRef<T>): void {
    this.applicationReference.detachView(componentReference.hostView);
    componentReference.destroy();
  }

  abstract initializeComponent(componentReference: ComponentRef<T>, initializationObject: InitializationObject): void;
}
