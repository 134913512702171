import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OffCanvasTriggerDirective } from '@/shared/directives/off-canvas-trigger.directive';

@Component({
  selector: 'app-off-canvas',
  templateUrl: './off-canvas.component.html',
  styleUrls: ['./off-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffCanvasComponent {
  @ViewChild(OffCanvasTriggerDirective, { static: false }) anchor!: OffCanvasTriggerDirective;

  @HostListener('document:mousedown', ['$event'])
  clickOutside(event: Event): void {
    const target = event.target as Node;
    if (!this.elementReference.nativeElement.contains(target)) {
      this.close();
    }
  }

  @Input()
  title!: string;

  @Input()
  templateRef!: TemplateRef<{ offCanvasTemplate: string }>;

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  dismiss: EventEmitter<Event> = new EventEmitter<Event>();

  // TODO: is Node the right type here?
  constructor(private readonly elementReference: ElementRef<Node>) {}

  close(): void {
    this.dismiss.next(new Event('close'));
  }
}
