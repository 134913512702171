import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavItemComponent } from '@core/components/layout/nav-item/nav-item.component';
import { FeatureFlags } from '@core/feature-flags';
import { AmlDeskRoutes, amlDeskRoutes } from '@core/route-map';
import { RoleDisplayName } from '@core/types/grant.types';
import { environment } from '@env/environment';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { KYCRoutes } from '@kyc/route-map-kyc';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthActions, LogoutReason } from '@/auth/store/actions/auth.actions';
import { selectAuthTokensAndOwnGrant } from '@/auth/store/selectors/combined-auth.selectors';

type NavItemConfig = {
  labelKey: string;
  route: string;
  icon?: string;
  allowedRoles: RoleDisplayName[];
  subItems?: NavItemConfig[];
  condition?: () => boolean;
};

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [NavItemComponent, TranslocoModule, AsyncPipe, NgIf],
})
export class NavComponent implements OnInit {
  featureFlags = FeatureFlags;

  /**
   * Disable the navigation
   * Its true when the connection is offline
   * */
  @Input() disabled = false;

  @Input() iconsOnly = false;

  @Output() itemClicked = new EventEmitter<void>();

  readonly routes: AmlDeskRoutes = amlDeskRoutes;

  userRole: RoleDisplayName = RoleDisplayName.BasicUser;

  navigationItems: NavItemConfig[] = [
    {
      labelKey: 'navigation.dashboard',
      route: this.routes.dashboard,
      icon: 'cop-home',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.transparency',
      route: this.routes.transparencyRegister,
      icon: 'cop-aml-registration',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.riskAnalysis',
      route: this.routes.riskAnalysis,
      icon: 'cop-crosshair',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.kycUserCreation',
      route: this.routes.kycUserCreation,
      icon: 'cop-kyc-check',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.academy',
      route: this.routes.academy,
      icon: 'cop-academy',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'kyc.navigation.introduction',
      route: this.routes.kyc,
      icon: 'cop-crosshair',
      allowedRoles: [RoleDisplayName.WorkItemUser],
      subItems: [
        {
          labelKey: 'kyc.navigation.create',
          route: this.getNavigationRoute(this.routes.kyc, KYCRoutes.create),
          icon: 'cop-edit',
          allowedRoles: [RoleDisplayName.WorkItemUser],
        },
        {
          labelKey: 'kyc.navigation.overview',
          icon: 'cop-eye-open',
          route: this.getNavigationRoute(this.routes.kyc, KYCRoutes.overview),
          allowedRoles: [RoleDisplayName.WorkItemUser],
        },
      ],
    },
    {
      labelKey: 'navigation.documents',
      route: this.routes.documents,
      icon: 'cop-documents',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.contact',
      route: this.routes.contact,
      icon: 'cop-contact-book',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer, RoleDisplayName.WorkItemUser],
    },
    {
      labelKey: 'navigation.frequentlyAskedQuestions',
      route: this.routes.faq,
      icon: 'cop-faq',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
  ];

  userRole$: Observable<RoleDisplayName> = this.store
    .select(selectAuthTokensAndOwnGrant())
    .pipe(map(({ ownGrant }) => ownGrant?.roleDisplayName ?? RoleDisplayName.BasicUser));

  navigationItems$ = of(this.navigationItems);

  filteredNavItems$: Observable<NavItemConfig[]> = combineLatest([this.navigationItems$, this.userRole$]).pipe(
    map(([navigationItems, userRole]) =>
      navigationItems.filter((item) => item.allowedRoles.includes(userRole) && (!item.condition || item.condition())),
    ),
  );

  constructor(
    private readonly store: Store,
    private readonly kycItemTypeCacheService: KycItemTypeCacheService,
  ) {
    // TODO: prefer using the async pipe in the template
    this.store.select(selectAuthTokensAndOwnGrant()).subscribe(({ ownGrant }) => {
      if (ownGrant) {
        this.userRole = ownGrant.roleDisplayName;
      }
    });
  }

  ngOnInit(): void {
    this.updateNavigationItemsLabel();
  }

  /**
   * Function triggers Logout Action
   */
  signOut(): void {
    if (this.disabled) {
      return;
    }
    this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.Manual }));
  }

  onItemClicked(): void {
    this.itemClicked.emit();
  }

  /**
   * Gets the navigation route based on the base and subSection provided.
   *
   * @param {string} base - The base URL for the navigation route.
   * @param {string} subSection - The subsection of the navigation route.
   * @return {string} The navigation route generated by combining the base and subSection.
   */
  getNavigationRoute(base: string, subSection: string): string {
    return `${base}/${subSection}`;
  }

  /**
   * Updates the labelKey of the KYC navigation item based on the work item type.
   * If `isDDWorkItemType` is true, the labelKey is set to 'navigationDD.title'.
   * Otherwise, the labelKey is set to 'navigationKyc.title'.
   *
   * @return {void}
   */
  private updateNavigationItemsLabel(): void {
    const isDDWorkItemType = this.isDDWorkItemType();
    const kycNavItem = this.navigationItems.find((item) => item.labelKey === 'kyc.navigation.introduction');

    if (kycNavItem) {
      kycNavItem.labelKey = isDDWorkItemType ? 'navigationDD.title' : 'navigationKyc.title';
    }
  }

  /**
   * Checks if any work item types include 'ddt-' in their reference name.
   * @return {boolean} Returns true if any work item type's reference name includes 'ddt-', otherwise false.
   */
  private isDDWorkItemType(): boolean {
    const workItemTypes = this.kycItemTypeCacheService.getWorkItemTypes();
    return workItemTypes ? workItemTypes.some((item) => item.referenceName?.includes('ddt-')) : false;
  }

  protected readonly environment = environment;
}
