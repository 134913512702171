<button
  [type]="buttonType"
  appRipple
  class="btn no-animation flex items-center justify-center text-base font-medium normal-case leading-5"
  [appRippleDisabled]="form === 'link'"
  [appRippleColor]="variant === 'error' || variant === 'primary' ? 'rgba(255, 255, 255, 0.4)' : ''"
  [class]="classList"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-error hover:bg-error-hover': variant === 'error',
    'btn-secondary btn-outline border-secondary text-secondary': variant === 'secondary',
    'text-white': variant === 'inverted',
    'text-brand bg-transparent border-0': variant === 'inverted-brand' && !disabled,
    'btn-circle': form === 'circle',
    'btn-link no-underline p-2 font-normal': form === 'link',
    'btn-xs': size === 'xs',
    'btn-sm': size === 'sm',
    'btn-lg': size === 'lg',
    'text-accent bg-base-content': disabled && variant !== 'inverted-brand',
    'text-base-content bg-base-100': disabled && variant === 'inverted-brand'
  }"
  [disabled]="disabled"
>
  <ng-container *ngIf="iconOnly; else withContent">
    <mat-icon *ngIf="icon" mat-list-icon [svgIcon]="icon" [inline]="inlineIcon"></mat-icon>
  </ng-container>
  <ng-template #withContent>
    <mat-icon class="mr-2" *ngIf="icon && !iconRight" mat-list-icon [svgIcon]="icon" [inline]="inlineIcon"></mat-icon>
    <ng-content></ng-content>
    <mat-icon class="ml-2" *ngIf="icon && iconRight" mat-list-icon [svgIcon]="icon" [inline]="inlineIcon"></mat-icon>
  </ng-template>
</button>
