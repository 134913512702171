import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectGlobalLoading } from '@src/app/store/selectors/global-loading.selectors';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-global-loading-spinner',
  templateUrl: './global-loading-spinner.component.html',
  styleUrls: ['./global-loading-spinner.component.scss'],
})
export class GlobalLoadingSpinnerComponent implements OnInit {
  loading$: Observable<boolean> = of(false);

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(selectGlobalLoading);
  }
}
