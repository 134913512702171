import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortTable } from '@kyc/types/kyc.types';
import { Papa } from 'ngx-papaparse';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Option } from '@/shared/components/fragments/dropdown/dropdown.component';
import { FileDownloadService } from '@/shared/services/file/file-download.service';
import { Sort, SortField, SortOrder } from '../../../pipes/sort-data.pipe';
import { WorkItemStatus } from '@kyc/enums/kyc.enum';
import { getTranslationKeyForStatus } from '@kyc/constants/kyc-constant';
import { TranslocoService } from '@jsverse/transloco';

type DefaultConstructor<T> = new (...arguments_: unknown[]) => T;

export type TemplateComponent<T, K> = {
  component: DefaultConstructor<K>;
  getContent?: (item: T) => string | undefined;
  getInputs?: (item: T) => Record<string, string>;
  getOutputs?: (item: T) => Record<string, (...arguments_: unknown[]) => void>;
};

export type TableColumn<T, K> = {
  title?: string;
  titleKey?: string;
  key: string;
  sortable?: boolean;
  callback?: (item: T) => string;
  template?: TemplateComponent<T, K>;
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
})
export class TableComponent<T, K> {
  @Input() columns!: TableColumn<T, K>[];

  @Input() data$!: Observable<T[]>;

  @Input() isZebra: boolean = false;

  @Input() evaluationFunctionForRowStyling!: (row: T) => string;

  @Input() clickable: boolean = false;

  @Input() showDownloadDropdown: boolean = false;

  @Input() rowClickHandler!: (row: T) => void;

  @Input() loading: boolean | null = false;

  @Input() error: string | undefined;

  @Input() downloadCsvText: string = '';

  @Input() entriesPerPageText: string = '';

  sortColumn?: string;

  pageSizeSelected: number = 25;

  @Output() pageChange = new EventEmitter<number>();

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  sort = new EventEmitter<Sort | SortTable | Option>();

  pageSizes: number[] = [25, 50, 100, 200];

  constructor(
    private readonly downloadFileService: FileDownloadService,
    private csvParser: Papa,
    private readonly translationService: TranslocoService,
  ) {}

  getEvaluation(tableRow: T): string {
    return this.evaluationFunctionForRowStyling === undefined ? '' : this.evaluationFunctionForRowStyling(tableRow);
  }

  onSort(field: SortField, order: SortOrder): void {
    this.sortColumn = order === '' ? undefined : field;
    this.sort.emit({ field, order });
  }

  onChangePageSize(size: number): void {
    this.pageSizeSelected = size;
    this.pageChange.emit(size);
  }

  createContentNode(content: unknown): Text[][] {
    if (!content) {
      return [];
    }
    if (typeof content === 'string' && content.length === 0) {
      return [];
    }

    return [[document.createTextNode(String(content))]];
  }

  /**
   * Downloads the current data as a CSV file.
   *
   * @returns {void}
   */
  downloadCSV(event: MouseEvent): void {
    event.stopPropagation();
    this.data$.pipe(take(1)).subscribe((data) => {
      const mappedData = data.map((row) => ({
        ...row,
        status: this.translationService.translate(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          'kyc.overviewPage.columns.status.' + getTranslationKeyForStatus(row.status as WorkItemStatus),
        ),
      }));
      const csvData = mappedData.map((row) => {
        const rowData: { [key: string]: unknown } = {};
        for (const column of this.columns) {
          rowData[column.title!] = column.callback ? column.callback(row) : row[column.key as keyof T];
        }
        return rowData;
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const csv = this.csvParser.unparse(csvData, { delimiter: ';' });
      this.downloadFileService.triggerDownloadCsv(csv);
    });
  }
}
