<ng-container *transloco="let t">
  <ng-container *ngIf="consent; else placeholder">
    <ng-content></ng-content>
  </ng-container>

  <ng-template #placeholder>
    <div class="mb-4 w-full bg-blue-hover px-3 py-14 md:px-8">
      <h4 *ngIf="cookieServiceName" class="mb-10 font-medium">
        {{ t('cookieConsent.cookieConsentMessages.' + cookieServiceName) }}
      </h4>
      <div class="flex flex-wrap-reverse items-center justify-center gap-2 sm:flex-nowrap">
        <app-button form="link" class="w-full max-w-42 flex-auto" (click)="showCookieSettings()">
          {{ t('cookieConsent.settingsButton') }}
        </app-button>
        <app-button variant="primary" class="w-full max-w-42 flex-auto" (click)="consentCookie()">{{
          t('cookieConsent.consentButton')
        }}</app-button>
      </div>
    </div>
  </ng-template>
</ng-container>
