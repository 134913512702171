import { Injectable } from '@angular/core';
import { storageKeyWorkItemTypes } from '@app/store/reducers/kyc-work-item-types.reducer';
import { WorkItemType } from '@kyc/types/kyc.types';

// TODO: candidate to be the abstraction over the selected storage method, localStorage, or sessionStorage
@Injectable({
  providedIn: 'root',
})
export class KycItemTypeCacheService {
  private storageKey = storageKeyWorkItemTypes;

  /**
   * Retrieves the work item types from session storage.
   * @returns {WorkItemType[] | undefined} - An array of work item types if found in session storage, otherwise undefined.
   */
  getWorkItemTypes(): WorkItemType[] | undefined {
    const data = sessionStorage.getItem(this.storageKey);
    if (!data) {
      return;
    }

    try {
      return JSON.parse(data) as WorkItemType[];
    } catch (error) {
      console.error('Failed to parse work item types from session storage:', error);
      return;
    }
  }

  /**
   * Sets the work item types in session storage
   * @param {WorkItemType[]} workItemTypes - an array of work item types to be stored in session storage
   * @return {void}
   */
  setWorkItemTypes(workItemTypes: WorkItemType[]): void {
    try {
      sessionStorage.setItem(this.storageKey, JSON.stringify(workItemTypes));
    } catch (error) {
      console.error('Failed to set work item types in session storage:', error);
    }
  }

  clearWorkItemTypes(): void {
    sessionStorage.removeItem(this.storageKey);
  }
}
