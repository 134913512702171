import { Gender } from '../enums/gender.enum';
import { Language } from '../enums/languages.enum';
import { Industry, User } from '../models/user.model';

// Azure AD token claims
export type UserTokenClaims = {
  extension_kerberosAccountId: string;
  extension_gender: Gender;
  extension_preferredLanguage: Language;
  extension_industry: Industry;
  oid: string;
  emails: string[];
  family_name: string;
  given_name: string;
};

/**
 * Generates a salutation for a user based on their gender and preferred language.
 *
 * @param {Gender} gender - The gender of the user. Expected values: Gender.Masculine, Gender.Feminine.
 * @param {Language} language - The language in which the salutation should be generated. Expected values: Language.Deutsch, Language.English.
 * @returns {string} - The appropriate salutation for the user.
 *
 * This method determines the salutation ('Herr' or 'Frau' for German, 'Mr' or 'Ms' for English) based on the provided gender and language.
 */
export const getUserSalutation = (gender: Gender, language: Language): string => {
  if (gender === Gender.Masculine) {
    return language === Language.Deutsch ? 'Herr' : 'Mr';
  }

  return language === Language.Deutsch ? 'Frau' : 'Ms';
};

/**
 * Function to map the user object from the iam provider for saving into state
 * @param idTokenClaims
 * @returns user object
 */
export const mapUserFromToken = (idTokenClaims: UserTokenClaims): User => {
  // Extract custom claims and rename them to make it easier to handle.
  const {
    extension_kerberosAccountId: kerberosAccountId,
    extension_gender: gender,
    extension_preferredLanguage: preferredLanguage,
    extension_industry: industry,
    given_name: givenName,
    family_name: lastName,
    oid: oid,
    emails: [email],
  } = idTokenClaims;

  const salutation = getUserSalutation(gender, preferredLanguage);

  return {
    uid: oid,
    email: [email][0],
    lastName: lastName,
    firstName: givenName,
    industry,
    kerberosAccountId,
    gender,
    preferredLanguage,
    salutation,
  };
};
