import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { amlDeskRoutes } from '@core/route-map';
import { AppConstants } from '@src/app/app.constants';
import { debounceTime, fromEvent, merge, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InactivityService implements OnDestroy {
  private idleTimeSubscription: Subscription | undefined = undefined;

  private readonly idleTime = AppConstants.UserInactiveTimeout;

  private readonly debounceTimeBetweenEvents = 500;

  constructor(private readonly router: Router) {}

  /**
   * Starts tracking user activity.
   *
   * @return {void}
   */
  startTracking(): void {
    const userActivityObservable = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'mouseover'),
      fromEvent(document, 'mouseout'),
      fromEvent(document, 'scroll'),
    ).pipe(
      debounceTime(this.debounceTimeBetweenEvents), // the debounced time could be less than a second
    );

    if (this.idleTimeSubscription) {
      this.idleTimeSubscription.unsubscribe();
    }

    this.idleTimeSubscription = userActivityObservable.pipe(switchMap(() => timer(this.idleTime))).subscribe(() => {
      this.stopTracking();
      void this.router.navigate([amlDeskRoutes.logout]);
    });
  }

  /**
   * Stops tracking the idle time.
   *
   * @return {void}
   */
  stopTracking(): void {
    if (this.idleTimeSubscription) {
      this.idleTimeSubscription.unsubscribe();
      this.idleTimeSubscription = undefined;
    }
  }

  ngOnDestroy(): void {
    this.stopTracking();
  }
}
