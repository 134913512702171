<div
  class="fixed bottom-0 left-0 z-50 flex size-full items-center justify-center bg-gray-cold-10"
  id="confirmation-banner"
>
  <div class="w-83 rounded-xl bg-primary-content p-5 shadow-lg md:w-160">
    <h2 class="mb-2 text-neutral">{{ title }}</h2>
    <p class="mb-6 text-base text-neutral">{{ text }}</p>
    <div class="mx-auto flex flex-col md:flex-row md:justify-end">
      <app-button variant="inverted-brand" class="w-full md:w-35" (click)="close()">{{ cancel }}</app-button>
      <app-button variant="primary" class="w-full md:w-35" (click)="confirmClick()">{{ confirm }}</app-button>
    </div>
  </div>
</div>
