import { createReducer, on } from '@ngrx/store';
import { KerberosAuthActions } from '../actions/kerberos-auth.actions';

const azureAccessTokenAzure = localStorage.getItem('accessTokenAzure');
const kerberosAccessToken = localStorage.getItem('accessTokenKerberos');
const kerberosRefreshToken = localStorage.getItem('refreshTokenKerberos');

export interface AuthStateTokens {
  accessTokenAzure: string | null;
  accessTokenKerberos: string | null;
  refreshTokenKerberos: string | null;
}

export const initialAuthStateTokens: AuthStateTokens = {
  accessTokenAzure: azureAccessTokenAzure,
  accessTokenKerberos: kerberosAccessToken!, // TODO: review this
  refreshTokenKerberos: kerberosRefreshToken,
};

export const authTokensReducer = createReducer(
  initialAuthStateTokens,
  on(
    KerberosAuthActions.setLoginTokens,
    (state: AuthStateTokens, action): AuthStateTokens => ({
      ...state,
      accessTokenAzure: action.accessTokenAzure,
      accessTokenKerberos: action.accessTokenKerberos,
      refreshTokenKerberos: action.refreshTokenKerberos,
    }),
  ),
  on(
    KerberosAuthActions.setRefreshTokens,
    (state: AuthStateTokens, action): AuthStateTokens => ({
      ...state,
      accessTokenKerberos: action.accessTokenKerberos,
      refreshTokenKerberos: action.refreshTokenKerberos,
    }),
  ),
  on(
    KerberosAuthActions.setLogoutTokens,
    (state: AuthStateTokens): AuthStateTokens => ({
      ...state,
      // eslint-disable-next-line unicorn/no-null
      accessTokenAzure: null,
      // eslint-disable-next-line unicorn/no-null
      accessTokenKerberos: null,
      // eslint-disable-next-line unicorn/no-null
      refreshTokenKerberos: null,
    }),
  ),
);

export const kerberosAuthTokensFeatureKey = 'auth-kerberos';
