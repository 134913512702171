import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import { DynamicModule } from 'ng-dynamic-component';
import { ContentWrapperComponent } from '@/shared/components/elements/content-wrapper/content-wrapper.component';
import { LinkComponent } from '@/shared/components/elements/link/link.component';
import { SearchComponent } from '@/shared/components/elements/search/search.component';
import { SupportFloatingButtonComponent } from '@/shared/components/fragments/support-floating-button/support-floating-button.component';
import { TextVideoSectionComponent } from '@/transparency-register/components/text-video-section.component';
import { BadgeComponent } from './components/elements/badge/badge.component';
// app components
import { ButtonComponent } from './components/elements/button/button.component';
import { CardComponent } from './components/elements/card/card.component';
import { FormFieldComponent } from './components/elements/form-field/form-field.component';
import { GlobalLoadingSpinnerComponent } from './components/elements/global-loading-spinner/global-loading-spinner.component';
// reducer
import { IconElementComponent } from './components/elements/icon-element/icon-element.component';
import { PaginatorComponent } from './components/elements/paginator/paginator.component';
import { PaginatorButtonComponent } from './components/elements/paginator/paginator-button.component';
import { SpinnerComponent } from './components/elements/spinner/spinner.component';
import { TrafficLightComponent } from './components/elements/traffic-light/traffic-light.component';
import { ConfirmationDialogComponent } from './components/fragments/confirmation-dialog/confirmation-dialog.component';
import { ContactCardComponent } from './components/fragments/contact-card/contact-card.component';
import { CookieContextualConsentComponent } from './components/fragments/cookie-contextual-consent/cookie-contextual-consent.component';
import { DropdownComponent } from './components/fragments/dropdown/dropdown.component';
import { FileUploadComponent } from './components/fragments/file-upload/file-upload.component';
import { FramedFormComponent } from './components/fragments/framed-form/framed-form.component';
import { OffCanvasComponent } from './components/fragments/off-canvas/off-canvas.component';
import { SortButtonComponent } from './components/fragments/table/sort-button.component';
import { TableComponent } from './components/fragments/table/table.component';
import { TaskItemComponent } from './components/fragments/task-item/task-item.component';
import { ToastComponent } from './components/fragments/toast/toast.component';
// directives
import { OffCanvasTriggerDirective } from './directives/off-canvas-trigger.directive';
import { RippleDirective } from './directives/ripple.directive';
// material
import { MaterialModule } from './material.module';
// pipes
import { ExtractKeyPipe } from './pipes/extract-key.pipe';
import { SortArrayAsyncPipe, SortArrayPipe } from './pipes/sort-data.pipe';
import { navFeatureKey, navReducer } from './store/reducers/nav.reducers';

@NgModule({
  declarations: [
    ButtonComponent,
    CardComponent,
    ContentWrapperComponent,
    TaskItemComponent,
    TableComponent,
    SortButtonComponent,
    PaginatorComponent,
    PaginatorButtonComponent,
    ExtractKeyPipe,
    DropdownComponent,
    SpinnerComponent,
    ToastComponent,
    SortArrayAsyncPipe,
    SortArrayPipe,
    OffCanvasComponent,
    OffCanvasTriggerDirective,
    FileUploadComponent,
    FormFieldComponent,
    SearchComponent,
    ContactCardComponent,
    ConfirmationDialogComponent,
    GlobalLoadingSpinnerComponent,
    CookieContextualConsentComponent,
    FramedFormComponent,
    TextVideoSectionComponent,
    SupportFloatingButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BadgeComponent,
    CommonModule,
    HttpClientModule,
    RouterModule,
    IconElementComponent,
    MaterialModule,
    StoreModule.forFeature(navFeatureKey, navReducer),
    DynamicModule,
    TranslocoModule,
    MatListModule,
    RippleDirective,
    LinkComponent,
    FormsModule,
    TrafficLightComponent,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinner,
  ],
  exports: [
    MaterialModule,
    ButtonComponent,
    CardComponent,
    ContentWrapperComponent,
    TaskItemComponent,
    TableComponent,
    SortButtonComponent,
    PaginatorComponent,
    PaginatorButtonComponent,
    ButtonComponent,
    DropdownComponent,
    SpinnerComponent,
    ToastComponent,
    SortArrayAsyncPipe,
    SortArrayPipe,
    OffCanvasTriggerDirective,
    FileUploadComponent,
    FormFieldComponent,
    ContactCardComponent,
    ConfirmationDialogComponent,
    GlobalLoadingSpinnerComponent,
    CookieContextualConsentComponent,
    FramedFormComponent,
    TextVideoSectionComponent,
    SupportFloatingButtonComponent,
    SearchComponent,
  ],
})
export class SharedModule {}
