import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorWindow } from './paginator.models';
import { computePaginatorWindow } from './paginator.utils';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent {
  @Input()
  pageCount!: number;

  @Input()
  hasMore: boolean | null = true;

  @Input()
  currentPage: number = 1;

  @Output()
  page = new EventEmitter<number>();

  private currentPaginatorWindow: PaginatorWindow = {
    values: [],
    start: this.currentPage,
    end: this.currentPage + 2,
  };

  get lastPage(): number {
    return this.pageCount - 1;
  }

  get pageWindow(): number[] {
    this.currentPaginatorWindow = computePaginatorWindow(this.currentPage, this.pageCount, this.currentPaginatorWindow);
    return this.currentPaginatorWindow.values;
  }

  changePage(newPage: number): void {
    this.currentPage = newPage < 1 ? 1 : newPage;

    this.page.emit(this.currentPage);
  }
}
