import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieConsentService } from '@src/app/core/services/cookie-consent.service';

export enum Cookies {
  Jotform = 'jotform',
  Kompassify = 'kompassify',
}

@Component({
  selector: 'app-cookie-contextual-consent',
  templateUrl: './cookie-contextual-consent.component.html',
})
export class CookieContextualConsentComponent implements OnInit {
  @Input()
  cookieServiceName!: Cookies | undefined;

  @Output()
  // eslint-disable-next-line unicorn/prefer-event-target
  consentUpdate = new EventEmitter<boolean>();

  private lastConsent!: boolean;

  constructor(private readonly cookieConsentService: CookieConsentService) {}

  get consent(): boolean {
    this.getCookieSavedConsent();
    return this.lastConsent;
  }

  ngOnInit(): void {
    this.lastConsent = this.getCookieSavedConsent();
  }

  showCookieSettings(): void {
    this.cookieConsentService.showSettingsDialog();
  }

  consentCookie(): void {
    if (this.cookieServiceName) {
      this.cookieConsentService.updateAndSaveConsent(this.cookieServiceName, true);
    }
  }

  private getCookieSavedConsent(): boolean {
    if (!this.cookieServiceName) {
      return false;
    }

    if (!this.cookieConsentService.getService(this.cookieServiceName)) {
      return false;
    }

    const savedConsent = this.cookieConsentService.getSavedConsent(this.cookieServiceName);
    if (this.lastConsent !== savedConsent) {
      this.lastConsent = savedConsent;
      this.consentUpdate.emit(savedConsent);
    }
    return savedConsent;
  }
}
