<div
  class="dropdown dropdown-end"
  [ngClass]="{
    'dropdown-opened': open
  }"
>
  <button
    appRipple
    tabindex="0"
    (click)="toggle()"
    (blur)="toggle()"
    class="flex w-full flex-row items-center justify-between rounded-md border border-brand bg-base-100 px-4 py-2 text-left text-brand shadow hover:bg-accent focus:border focus:border-brand focus:text-brand focus:outline-blue"
    [ngClass]="{
      'border border-brand text-brand outline-blue': _activeKeys.size > 0
    }"
  >
    <ng-content></ng-content>
    <mat-icon *ngIf="icon" [class]="iconClasses" class="focus-visible::text-brand" svgIcon="{{ icon }}"></mat-icon>
  </button>
  <ul tabindex="0" class="dropdown-content menu w-52 rounded-md bg-base-100 py-2 shadow">
    <li appRipple *ngFor="let option of options">
      <a [ngClass]="{ 'text-brand bg-base-100': _activeKeys.has(option.value) }" (click)="onClick(option)" class="flex flex-row justify-between">
        {{ option.title }}
        <mat-icon *ngIf="_activeKeys.has(option.value)" svgIcon="cop-trashbin"></mat-icon>
      </a>
    </li>
  </ul>
</div>
