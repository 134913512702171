<ng-container *transloco="let t">
  <footer class="bg-gray-400">
    <div class="grid grid-cols-3 gap-x-8 gap-y-5 p-6 text-xs md:flex md:justify-center md:p-4">
      <a
        class="link link-primary no-underline sm:mx-auto md:mx-1"
        href="https://www.kerberos-compliance.com/rechtliches/impressum/"
        target="_blank"
        rel="noopener"
      >
        {{ t('footer.imprint') }}
      </a>
      <a
        class="link link-primary no-underline sm:mx-auto md:mx-1"
        href="https://www.kerberos-compliance.com/rechtliches/datenschutz/"
        target="_blank"
        rel="noopener"
      >
        {{ t('footer.privacy') }}
      </a>
      <a class="link link-primary no-underline sm:mx-auto md:mx-1" (click)="showCookieConsentSettingsDialog()">
        {{ t('footer.cookieConsent') }}
      </a>
    </div>
  </footer>
</ng-container>
