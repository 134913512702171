import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-paginator-button',
  templateUrl: './paginator-button.component.html',
})
export class PaginatorButtonComponent {
  @Input()
  disabled: boolean = false;

  @Input()
  active: boolean = false;

  @Input()
  icon: boolean = false;
}
