import { UUID } from 'node:crypto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '@core/enums/languages.enum';
import { EnvironmentService } from '@core/services/environment.service';
import { WorkItemStatus } from '@kyc/enums/kyc.enum';
import { CreateDocumentBodyDto, CreateMessageDto, CreateWorkItemDto } from '@kyc/types/kyc.dto';
import {
  CommunicationThread,
  DataReference,
  Message,
  PaginatedResponse,
  PaginationQuery,
  SearchWorkItem,
  WorkItem,
  WorkItemFormData,
  WorkItemFormFieldConfigResponse,
  WorkItemType,
} from '@kyc/types/kyc.types';
import { map, Observable } from 'rxjs';
import { compareByKey } from '@/shared/pipes/sort-data.pipe';

@Injectable({ providedIn: 'root' })
export class KycService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Retrieves a work item by its ID.
   *
   * @param {UUID} workItemId - The ID of the work item to be retrieved.
   * @return {Observable<WorkItem>} - An observable that emits the workitem.
   */
  getWorkItemById(workItemId: UUID): Observable<WorkItem> {
    return this.httpClient.get<WorkItem>(`${this.environmentService.baseAdpHost}/work-item/details/${workItemId}`, {
      withCredentials: true,
    });
  }

  /**
   * Creates a new work item with the specified type and display name.
   *
   * @param {number} workItemTypeId - The ID representing the type of work item to create.
   * @param {string} displayName - The display name for the new work item.
   * @return {Observable<WorkItem>} An Observable that will emit the created WorkItem object.
   */
  createWorkItem(workItemTypeId: number, displayName: string): Observable<WorkItem> {
    const body: CreateWorkItemDto = {
      workItemTypeId: workItemTypeId,
      status: WorkItemStatus.ActionInternal,
      displayName,
    };
    return this.httpClient.post<WorkItem>(`${this.environmentService.baseAdpHost}/work-item`, body, {
      withCredentials: true,
    });
  }

  /**
   * Creates a draft for a new work item.
   *
   * @param {number} workItemTypeId - The ID representing the type of the work item.
   * @param {string} displayName - The title for the draft work item.
   * @return {Observable<WorkItem>} An observable that emits the created draft work item.
   */
  createWorkItemDraft(workItemTypeId: number, displayName: string): Observable<WorkItem> {
    const body: CreateWorkItemDto = {
      workItemTypeId: workItemTypeId,
      displayName,
    };
    return this.httpClient.post<WorkItem>(`${this.environmentService.baseAdpHost}/work-item/draft`, body, {
      withCredentials: true,
    });
  }

  /**
   * Submits a draft work item to the server for processing.
   *
   * @param {string} workItemId - The unique identifier of the work item to be submitted.
   * @return {Observable<boolean>} An observable that resolves to true if the submission is successful, and false otherwise.
   */
  submitWorkItemDraft(workItemId: UUID): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}/submit`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Updates the specified work item with the given details.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to update.
   * @return {Observable<WorkItem>} An observable containing the updated work item.
   */
  updateWorkItem(workItemId: UUID): Observable<WorkItem> {
    const body = {
      workItemId,
      displayName: 'generated via frontend',
    };
    return this.httpClient.put<WorkItem>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}`,
      body,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Retrieves the communication thread of an item.
   *
   * @param {UUID} workItemId - The UUID of the work item.
   * @returns {Observable<CommunicationThread>} - An Observable that emits the communication thread of the specified work item.
   */
  getCommunicationThreadOfWorkItem(workItemId: UUID): Observable<CommunicationThread> {
    return this.httpClient
      .get<CommunicationThread>(
        `${this.environmentService.baseAdpHost}/communication/work-item/${workItemId}/thread?pageSize=50`,
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((ele) => {
          ele.messages.sort((a, b) => compareByKey<Message>(a, b, { field: 'createdAt', order: 'DESC' }));
          return ele;
        }),
      );
  }

  /**
   * Retrieves the form field configuration for a specific work item type.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {Language} [language=Language.Deutsch] - The language in which to retrieve the form field configuration.
   * @return {Observable<WorkItemFormFieldConfigResponse>} An observable containing the form field configuration response for the specified work item type.
   */
  getFormFieldConfigForWorkItemType(workItemId: UUID, language: Language): Observable<WorkItemFormFieldConfigResponse> {
    return this.httpClient.get<WorkItemFormFieldConfigResponse>(
      `${this.environmentService.baseAdpHost}/workflow/work-item/${workItemId}/current-step/form-definition?language=${language}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Submits the form data for a work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {object} model - The data model representing the form data.
   * @param {string} formType - The type of the form being submitted.
   * @param {string} displayName - The display name for the form data.
   * @return {Observable<WorkItemFormData>} An observable containing the response form data for the submitted work item.
   */
  submitWorkItemFormData(
    workItemId: UUID,
    model: object,
    formType: string,
    displayName: string,
  ): Observable<WorkItemFormData> {
    const body: CreateDocumentBodyDto = {
      displayName: displayName,
      documentBody: model,
      formType,
    };
    return this.httpClient.post<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data`,
      body,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Updates the form data of a specified work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item.
   * @param {object} model - The data model to be updated.
   * @param {string} displayName - The display name associated with the form data.
   * @param {UUID} dataReference - The unique identifier for the data reference.
   * @return {Observable<WorkItemFormData>} An observable containing the updated work item form data.
   */
  updateWorkItemFormData(
    workItemId: UUID,
    model: object,
    displayName: string,
    dataReferenceId: UUID,
  ): Observable<WorkItemFormData> {
    return this.httpClient.put<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data`,
      {
        documentBody: model,
        displayName,
        dataReferenceId,
      },
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Retrieves the questionaire of an item.
   *
   * @param {UUID} workItemId - The UUID of the work item.
   *
   * @returns {Observable<JSON>} - An Observable that emits the DetailStatusData of the specified work item.
   */
  // TODO: backend definition for different detail_data JSON data is not yet defined. so for now we take JSON as return type
  loadWorkItemFormData(workItemId: UUID, formType: string): Observable<WorkItemFormData> {
    return this.httpClient.get<WorkItemFormData>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/form-data?formType=${formType}`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Creates a new message in the communication thread.
   *
   * @param {UUID} threadId - The ID of the communication thread.
   * @param {CreateMessageDto} message - The content of the message.
   * @returns {Observable<Message>} - The Observable that represents the creation of the message.
   */
  createNewMessage(threadId: UUID, message: CreateMessageDto): Observable<Message> {
    return this.httpClient.post<Message>(
      `${this.environmentService.baseAdpHost}/communication/thread/${threadId}/message`,
      message,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Finds all work items based on the given pagination query.
   *
   * @param {PaginationQuery} pagination - The pagination query to be used for filtering the work items.
   * @return {Observable<PaginatedResponse<WorkItem>>} - An observable that emits a paginated response containing the found work items.
   */
  findAllWorkItems(pagination: PaginationQuery): Observable<PaginatedResponse<WorkItem>> {
    const queryParameters = { ...pagination, defaultOrderFor: 'AML_DESK' };
    return this.httpClient.get<PaginatedResponse<WorkItem>>(`${this.environmentService.baseAdpHost}/work-item`, {
      params: queryParameters,
      withCredentials: true,
    });
  }

  /**
   * Finds work items by the given search parameters.
   *
   * @param {SearchWorkItem} searchWorkItem - The search parameters to filter the work items.
   *
   * @return {Observable<PaginatedResponse<WorkItem>>} - An Observable that emits the paginated response containing the work items.
   */
  findWorkItemsByParams(searchWorkItem: SearchWorkItem): Observable<PaginatedResponse<WorkItem>> {
    let parameters = new HttpParams();
    for (const key of Object.keys(searchWorkItem)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (searchWorkItem[key]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        parameters = parameters.append(key, searchWorkItem[key]);
      }
    }

    return this.httpClient.get<PaginatedResponse<WorkItem>>(`${this.environmentService.baseAdpHost}/work-item/search`, {
      params: parameters,
      withCredentials: true,
    });
  }

  /**
   * Retrieves the available work item types.
   *
   * @return {Observable<WorkItemType[]>} - An Observable that emits an array of WorkItemType objects.
   */
  getWorkItemTypes(): Observable<WorkItemType[]> {
    return this.httpClient.get<WorkItemType[]>(`${this.environmentService.baseAdpHost}/work-item/work-item-types`, {
      withCredentials: true,
    });
  }

  /**
   * Cancels the specified work item.
   *
   * @param {UUID} workItemId - The unique identifier of the work item to be canceled.
   * @return {Observable<WorkItem>} An observable emitting the canceled work item.
   */
  cancelWorkItem(workItemId: UUID): Observable<WorkItem> {
    return this.httpClient.put<WorkItem>(
      `${this.environmentService.baseAdpHost}/work-item/details/${workItemId}/cancel`,
      {
        withCredentials: true,
      },
    );
  }

  /**
   * Resolves Metadata of provided dataReferences
   * @param dataReferenceIds
   * @returns
   */
  getDataReferencesByWorkItemId(workItemId: UUID): Observable<DataReference[]> {
    return this.httpClient.get<DataReference[]>(
      `${this.environmentService.baseAdpHost}/work-item/data/${workItemId}/reference`,
      {
        withCredentials: true,
      },
    );
  }
}
