import { Component, Input } from '@angular/core';
import { AppConstants } from '@app/app.constants';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
})
export class TaskItemComponent {
  @Input() taskDate!: Date;

  @Input() taskTitle!: string;

  get dateFormat(): string {
    return AppConstants.DisplayDateFormat;
  }
}
