import { Pipe, PipeTransform } from '@angular/core';

/**
 * Extract the key from each object in an array and return new array with only the value for each key
 */
@Pipe({
  name: 'extractKey',
})
export class ExtractKeyPipe implements PipeTransform {
  transform<T extends Record<string, unknown>, K extends keyof T>(objects: T[], key: K): Array<T[K]> {
    const objectsToTransform = objects ?? [];
    // eslint-disable-next-line security/detect-object-injection
    return objectsToTransform.map((element) => element[key]).filter((value) => value !== null && value !== undefined);
  }
}
