import {
  ConfigEnvironment,
  Environment,
  EnvironmentsAmlDesk,
  Folders,
  LocalizedPageForms,
} from '@env/types.environment';

/**
 * DMS Folders
 */
const folders: Folders = {
  shared: '426dd8ba-db96-48fa-9901-bc4bd4de7976',
  realEstate: 'bf8d061d-175b-45d0-b86e-5cb52d21f34e',
  tradeInGoods: 'e24252b8-0af6-499f-807a-c16cf1e91e96',
  noSubscription: 'a601362b-33aa-4324-b413-3e7f41271c04',
  subscription: 'b58dccc0-6b3c-45d7-bffd-8cdbb48bff16',
};

/**
 * JOTFORM formIds
 */
const forms: LocalizedPageForms = {
  en: {
    'kyc-home': '231274201998358',
    'academy-home': '231515163359354',
    'contact-home': '231275364295359',
    faq: {
      'money-laundering': '231832551523047',
      academy: '231832968754065',
      'aml-desk': '231832916406052',
      'risk-analysis': '231832936804056',
      'kyc-app': '231832648514054',
    },
    feedback: '232571266309356',
  },
  de: {
    'kyc-home': '231274201998358',
    'academy-home': '231515163359354',
    'contact-home': '231275364295359',
    faq: {
      'money-laundering': '231832702025042',
      academy: '231832737268059',
      'aml-desk': '231832234461046',
      'risk-analysis': '231832415178052',
      'kyc-app': '231832153415045',
    },
    feedback: '232483877576069',
  },
};

const TIME_OUT = 10_000;

/**
 * App Configurations.
 */
const config: ConfigEnvironment = {
  sentry_dsn: 'https://655ca6a9a542d078f60364cae4a92a58@o4505991018840064.ingest.us.sentry.io/4505991089094656',
  featureFlags: {
    url: 'https://core-feature-flags-gateway-1rf3127t.ew.gateway.dev',
  },
  dms: {
    url: 'https://dms.dev.kerberos.vision',
    timeout: TIME_OUT,
    tags: ['cop'],
    folders,
  },
  msal: {
    clientId: 'a4e71483-c1cf-4d3b-97ba-8ca566a66405',
    tenantId: '812ad706-27d1-422c-8ce4-8d289d5e67d6',
    policy: 'B2C_1_SignIn',
  },
  sso_url: 'preview.iam.aml-desk.eu',
  adpHost: {
    url: 'https://preview.api.aml-desk.eu',
    signIn: 'sign-in',
  },
  forms,
  // We need the tenant on the first request when creating a new user, as we do not have the tenant in the token from Azure
  tenant: '117b36f6-aaf5-467a-883d-65e9a34ce198',
  registrationGuideDocGoAml: 'https://storage.googleapis.com/risk-analysis-templates/Leitfaden_Registrierung_goAML.pdf',
};

export const environment: Environment = {
  productionBuild: true,
  target: EnvironmentsAmlDesk.Development,
  debugFlags: [],
  config,
};
