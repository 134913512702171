import { AppContract } from '@core/models/contract.model';
import { createAction, props } from '@ngrx/store';

const loadContract = createAction('[Contract] Load Contract', props<{ kerberosAccountId: string }>());

const setContractInfoRequested = createAction(
  '[Contract] Contract Update Was Requested',
  props<{ contractInfoRequested: boolean }>(),
);

const loadContractSuccess = createAction('[Contract] Load Contract Success', props<{ contract: AppContract }>());

const loadContractFailure = createAction('[Contract] Load Contract Failure');

export const ContractActions = { loadContract, loadContractSuccess, loadContractFailure, setContractInfoRequested };
