import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { RippleDirective } from '@/shared/directives/ripple.directive';

export type ItemVariant = '' | 'logout' | 'secondaryNav' | 'secondaryNavMobile';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  standalone: true,
  imports: [RouterModule, NgClass, MatIconModule, NgIf, RippleDirective],
})
export class NavItemComponent {
  @Input() route: string | undefined;

  @Input() disabled = false;

  @Input() icon?: string = '';

  @Input() iconPosition: 'left' | 'right' = 'left';

  @Input() iconOnly = false;

  @Input() textOnly = false;

  @Input() variant: ItemVariant = '';

  @Input() 'data-cy' = '';

  // eslint-disable-next-line unicorn/prefer-event-target
  @Output() itemClicked = new EventEmitter<void>();

  get routePath(): string | undefined {
    if (!this.route || this.route.startsWith('/')) {
      return this.route;
    }

    return `/${this.route}`;
  }

  onItemClicked(): void {
    this.itemClicked.emit();
  }
}
